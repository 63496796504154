@import '../../../../styles/customMediaQueries.css';

.root {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    @media (--viewportLarge) {
        flex-direction: row;
    }
}

.media {
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (--viewportLarge) {
        width: 25%;
    }
}

.text {
    text-align: left;
    width: 100%;
}

.listItemText {
    display: flex;
    flex-direction: row;

    gap: 8px;

    text-align: left;

    & > p {
        color: var(--marketplaceColor)!important;
    }
}

.check {
    width: 24px;
    height: 24px;
    margin-right: 16px;
  
    @media (--viewportMedium) {
      width: 32px;
      height: 32px;
    }
  }