.root {
  position: relative;
}

.input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;

  /* Highlight the borders if the checkbox is hovered, focused or checked */
  &:hover + label .box,
  &:focus + label .box,
  &:checked + label .box {
    stroke: var(--marketplaceColor);
  }

  /* successColor version */
  &:hover + label .boxSuccess,
  &:focus + label .boxSuccess,
  &:checked + label .boxSuccess {
    stroke: var(--successColor);
  }

  /* Display the "check" when checked */
  &:checked + label .checked {
    display: inline;
    stroke: var(--marketplaceColor);
    stroke-width: 1px;
  }

  /* Display the "check" when checked */
  &:checked + label .checkedSuccess {
    display: inline;
    stroke: var(--successColor);
    stroke-width: 1px;
  }

  /* Hightlight the text on checked, hover and focus */
  &:focus + label .text,
  &:hover + label .text,
  &:checked + label .text {
    color: var(--matterColorDark);
  }
}

.label {
  display: flex;
  align-items: center;
  padding: 0;
}

.labelToggle {
  display: flex;
  justify-content: space-between;
}

.rootToggle {
  display: flex;
  justify-content: space-between;
}

.toggleContainer {
  display: flex;
  align-items: center;
}

.switch {
  position: relative;
  display: inline-block;
  width: 42px;
  height: 24px;
  margin: 0 8px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  /* background-color: #2196F3; */
  background-color: var(--successColor);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--successColor);
}

input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.checkboxWrapper {
  /* This should follow line-height */
  height: 32px;
  margin-top: -1px;
  margin-right: 12px;
  align-self: baseline;

  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.checked {
  display: none;
  fill: var(--marketplaceColor);
}
.checkedSuccess {
  display: none;
  fill: var(--successColor);
}

.boxSuccess,
.box {
  stroke: var(--matterColorAnti);
}

.text {
}
.textRoot {
  composes: marketplaceListingAttributeFontStyles from global;
  color: var(--matterColorBright);
  margin-top: -1px;
  margin-bottom: 1px;
  cursor: pointer;
}
