@import '../../styles/customMediaQueries.css';

.ourTopPicks {
  display: flex;
  flex-direction: column;
  background-color: var(--secondaryMarketplaceColor);
  padding: 48px 24px;
  width: 100%;

  @media (--viewportLarge) {
    padding: 96px;
  }
}

.ourTopPicksTexts {
  position: relative;
  color: #000;
  top: 0;
  margin: 2% 0 0 0;
  text-align: center;
}

.otpTitle {
  composes: h2 from global;

  color: #0A195A;
  margin: 0 0 24px 0;
}

.otpCardsContainer{
  width: 100%;
  height: auto;
  padding: 0;
  align-items: stretch;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 24px;

  @media (--viewportLarge) {
    flex-direction: row;
  }
}

.otpViewAll span {
  cursor: pointer;
}

.otpCardOne{
  width: 100%;
  height: 520px;
  position: relative;
  border-radius: 15px 15px 15px 15px;

  @media (--viewportLarge) {
    width: 30%;
    height: 520px;
  }
}

.otpCardImage{
  width: 100%;
  height: 60%;
  border-radius: 15px 15px 15px 15px;
}
.otpCardImage img {
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 15px 15px 15px 15px;
}
.otpCardDescription{
  width: 100%;
  height: 40%;
}

.otpCardTexts{
  width: 100%;
  text-align: left;
  padding: 3% 0 0 0;
  font-family: var(--fontFamilyRegular);
}
.otpCardTexts h4 {
  font-size: 30px;
  margin: 3% 0 0 0;
  font-family: 'HumanSans-Medium', Arial, Helvetica, sans-serif;
  color: var(--attentionColor);
}

.otpCardTexts p {
  margin: 3% 0 3% 0;
  font-family: var(--fontFamilyRegular);
  color: #000;
  font-size: 24px;
}


.otpTags
{
  width: 100%;
  padding: 0 5% 0 0;
  display: flex;
}

.otpMainTag{
  width: 100px;
  padding: 1px 15px 1px 15px;
  border-radius: 4px;
  color: #fff;
  background-color: var(--successColor);
  margin: 0 2% 0 2%;
}
.otpSecondaryTag{
  width: 100px;
  padding: 1px 15px 1px 15px;
  border-radius: 4px;
  color: #fff;
  background-color: #87AFE6;
  margin: 0 2% 0 2%;
}

.otpMainTag span{
  font-family: var(--fontFamilyRegular);
}

.otpSecondaryTag span{
  font-family: var(--fontFamilyRegular);
}

.card {
  width: 100%;

  @media (--viewportLarge) {
    width: 30%;
  }
}

/* Create listing (CTA for providers) */
.viewAllButton {
  border: 1px solid var(--marketplaceColor);
  border-radius: 10px;
  transition: var(--transitionStyleButton);
  font-family: var(--fontFamilyRegular);

  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 0;

  flex-shrink: 0;
  color: var(--marketplaceColor);
  margin: 16px auto;
  padding: 16px 24px;
  text-decoration: none;

  width: fit-content;
  cursor: pointer;

  &:hover {
    color: var(--attentionColor);
    border: 1px solid var(--attentionColor);
    background-color: rgba(40, 90, 200, .2);
    text-decoration: none;
  }

  &a {
    &:hover {
      color: var(--attentionColor);
      border: 1px solid var(--attentionColor);
      background-color: rgba(40, 90, 200, .2);
      text-decoration: none;
    }
  }

  @media (--viewportMedium) {
    font-size: 18px;
    line-height: 16px;
  }
}

