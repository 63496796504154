@import '../../styles/customMediaQueries.css';

.root {
  flex-grow: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  background: var(--marketplaceColor);
}

.form {
  flex-grow: 1;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  height: 124px;
  margin-bottom: 24px;

  @media (--viewportLarge) {
    margin-bottom: 36px;
  }
}

.title {
  font-size: 24px;
  font-weight: var(--fontWeightMedium);
  margin: auto 0;

  @media (--viewportLarge) {
    margin: auto 0;
    padding: 1px 0 7px 0;
  }
}

.number {
  font-size: 62px;
  font-weight: var(--fontWeightBold);
  margin: auto 0;
  color: #FFFFFF15;
}

.emphasis {
  color: var(--successColor);
}

.modal {
  padding: 0;
  margin: calc(var(--topbarHeight)) 0;
  z-index: 999;

  @media (--viewportMedium) {
    margin: calc(var(--topbarHeightDesktop)/2) 0;
  }
}

.modalContainer {
  flex-grow: 1;

    /* Create context so the close button can be positioned within it */
    position: relative;

    /* Display and dimensions */
    display: flex;
    flex-direction: column;

    background-color: var(--matterColorLight);
    border-radius: var(--borderRadius);
    border-bottom: none;
    padding-top: 64px;
    align-items: flex-end;

    min-height: 100vh;
    height: 100%;

    @media (--viewportMedium) {
      flex-basis: 70%;
      flex-grow: 0;
      min-height: 500px;
      height: auto;

      margin-top: 12.5vh;
      margin-bottom: 12.5vh;
      border-bottom: 8px solid var(--marketplaceColor);
    }
}

.modalContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  @media (--viewportMedium) {
    height: 500px;
  }
}
