@import '../../styles/customMediaQueries.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;

  padding-top: 1px;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  height: 124px;
  margin-bottom: 24px;

  @media (--viewportLarge) {
    margin-bottom: 36px;
  }
}

.title {
  color: var(--matterColorBright);
  font-size: 24px;
  line-height: 32px;
  font-weight: var(--fontWeightMedium);
  margin: auto 0;

  @media (--viewportLarge) {
    margin: auto 0;
    padding: 1px 0 7px 0;
  }
}

.titleInput {
  color: var(--matterColorBright);
  font-weight: 600;
  margin: auto 48px auto 0;
  padding: 1px 48px 7px 0;
  width: calc(100% - 48px);
  
  &::placeholder {
    font-weight: 500;
  }

  @media (--viewportLarge) {
    margin: auto 0;
    padding: 1px 0 7px 0;
    width: 100%;
  }
}

.input {
  color: var(--matterColorBright);
  font-weight: 600;

  &::placeholder {
    font-weight: 500;
  }
}

.selectSuccess {
  color: var(--matterColorBright);
  font-weight: 600;
}

.number {
  font-size: 62px;
  font-weight: var(--fontWeightBold);
  margin: auto 0;
  color: #FFFFFF15;
}

.emphasis {
  color: var(--successColor);
}

.break {
  display: block;

  @media (--viewportMedium) {
    display: none;
  }
}

.error {
  color: var(--failColor);
}

.formMargins {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.stepOne {
  display: none;
}

.stepTwo {
  display: none;
}

.stepThree {
  display: none;
}

.active {
  display: flex;
  flex-direction: column;
}

.title {
  composes: formMargins;
}

.holder {
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (--viewportMedium) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.half {
  composes: formMargins;
  width: 100%;

  @media (--viewportMedium) {
    width: calc(50% - 24px);
  }
}

.description {
  composes: formMargins;
  flex-shrink: 0;
}

.category {
  composes: formMargins;
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (--viewportMedium) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.label {
  color: var(--successColor);
  margin: 0 0 36px ;
}

.item {
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 15px;
  padding: 0;
  height: 100px;

  @media (--viewportMedium) {
    height: auto;
    width: 50%;
  }

  @media (--viewportLarge) {
    width: 25%;
  }
}

.radio {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.checked .img {
  border: 3px solid var(--successColor);
}

.checkbox {
  margin-top: 24px;
}

.img {
  border-radius: 15px;
  object-fit: cover;
  cursor: pointer;
  height: 100px;
  width: 100%;
  
  @media (--viewportMedium) {
    height: auto;
  }
}

.itemLabel {
  font-size: 24px;
  text-align: center;
  margin: 0;
  width: 100%;
  position: absolute;
  top: calc(50% - 12px);
  left: 0;
  cursor: pointer;

  @media (--viewportMedium) {
    top: calc(50% - 24px);
  }
}

.typeInput {
  composes: formMargins;
  color: var(--inputColor);
}

.buttonWrapper {
  display: flex;
  flex-direction: column;
  

  @media (--viewportLarge) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: fit-content;
    padding: 0 24px;
    margin-top: 100px;
    margin-left: auto;
  }
}

.listingButton {
  margin: 0 0 24px 0;
  flex-shrink: 0;
  background: var(--marketplaceColor);
  border: 1px solid var(--lightSecondary);
  color: var(--lightSecondary);

  &:hover,
  &:focus {
    color: var(--attentionColor);
    border: 2px solid var(--attentionColor);
    background-color: rgba(40, 90, 200, .2);
    text-decoration: none;
  }

  @media (--viewportLarge) {
    display: inline-block;
    width: fit-content;
    padding: 0 24px;
    margin: 100px 24px 24px;
  }
}

.backButton {
  margin-top: 24px;
  margin-bottom: 24px;
  flex-shrink: 0;
  background: var(--marketplaceColor);
  border: none;
  color: var(--lightSecondary);

  &:hover {
    color: var(--attentionColor);
    border: 2px solid var(--attentionColor);
    background-color: rgba(40, 90, 200, .2);
    text-decoration: none;
  }


  @media (--viewportLarge) {
    display: inline-block;
    width: fit-content;
    padding: 0 24px;
    margin-top: 100px;
  }
}

.locationLabel {
  font-weight: 500;
}

.locationAutocompleteInput {
  flex-grow: 1;
  height: 36px;
  line-height: unset;
  padding-left: 0;
  margin: 0;
  border-bottom: 2px solid var(--inputColor);
  color: var(--matterColorBright);
  font-weight: 600;

  &:hover,
  &:focus {
    border-bottom-color: var(--matterColorDark);
    outline: none;
  }

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  &::placeholder {
    color: var(--inputColor);
    font-weight: 500;
  }

  @media (--viewportMedium) {
    font-weight: 600;
    height: 40px;
  }
}

.locationAutocompleteInputIcon {
  display: none;
}

.predictionsRoot {
  position: absolute;
  width: 100%;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  top: 36px;
  left: 0;
  background: var(--secondaryMarketplaceColor);
  color: var(--marketplaceColor)!important;
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  & ul {
    & li {
      color: var(--marketplaceColor);

      &:hover,
      &.highlighted {
        cursor: pointer;
        border-left: 6px solid var(--marketplaceColor);
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        color: var(--marketplaceColor);
      }
    }
  }
  @media (--viewportMedium) {
    top: 40px;
  }
}

.predictionsRoot .predictions {
  & li {
    color: var(--marketplaceColor)!important;
    
  }
}

.validLocation {
  border-bottom-color: var(--successColor);
}

.locationAddress {
  flex-shrink: 0;
}

.tip {
  margin-top: 4px;
  font-size: 12px;
  color: var(--inputColor);
}

.icon {
  margin: 0 8px 0 0;
}

.tooltipDescription {
  display: flex;
  flex-direction: row;
  font-size: 16px;
  color: var(--matterColorDark);
}

.toolTipIcon {
  padding: 6px 0;
  height: auto;
  margin: auto 8px auto 0;
  min-width: 25%;
}

.tooltip {
  font-size: 16px;
  color: var(--lightSecondary);

  position: relative;
  width: fit-content;
}

.step {
  margin-right: 24px;
}

.description {
  margin: 8px 0;
}

.link {
  color: var(--successColor);

  &:hover {
    text-decoration: underline;
  }
}

.emphasis {
  color: var(--successColor);
}

.tooltipContainer {
  display: none;
  color: var(--marketplaceColor);
}

.tooltipContainerActive {
  display: flex;
  flex-direction: column;
  background: var(--matterColorBright);
  border-radius: 15px;
  align-items: center;
  gap: 8px;
  position: absolute;
  top: 0;
  left: 0;
  padding: 24px;
  width: calc(100vw - 72px);

  @media (--viewportMedium) {
    width: 500px;
  }
}

.tooltipHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: var(--attentionColor);
  width: 100%;
}
