@import '../../styles/customMediaQueries.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Display format */
  display: flex;
  flex: 1;
  flex-direction: column;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.label {
  color: var(--successColor);
  font-size: 16px;
  font-weight: var(--fontWeightSemiBold);
  margin: 0 0 36px ;
}

.input {
  color: var(--matterColorBright);
  font-weight: 600;
  
  &::placeholder {
    font-weight: 500;
  }
}

.locationAutocompleteInput {
  flex-grow: 1;
  height: 36px;
  line-height: unset;
  padding-left: 0;
  margin: 0;
  border-bottom: 2px solid var(--attentionColor);

  &:hover,
  &:focus {
    border-bottom-color: var(--matterColorDark);
    outline: none;
  }

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  @media (--viewportMedium) {
    height: 40px;
  }
}

.locationAutocompleteInputIcon {
  display: none;
}

.predictionsRoot {
  position: absolute;
  width: 100%;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  top: 36px;
  left: 0;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  @media (--viewportMedium) {
    top: 40px;
  }
}

.validLocation {
  border-bottom-color: var(--successColor);
}

.locationAddress,
.building {
  flex-shrink: 0;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.buttonWrapper {
  display: flex;
  flex-direction: column;
  

  @media (--viewportLarge) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: fit-content;
    padding: 0 24px;
    margin-top: 100px;
    margin-left: auto;
  }
}

.listingButton {
  margin: 0 0 24px 0;
  flex-shrink: 0;
  background: var(--marketplaceColor);
  border: 1px solid var(--lightSecondary);
  color: var(--lightSecondary);

  &:hover,
  &:focus {
    color: var(--attentionColor);
    border: 2px solid var(--attentionColor);
    background-color: rgba(40, 90, 200, .2);
    text-decoration: none;
  }

  @media (--viewportLarge) {
    display: inline-block;
    width: fit-content;
    padding: 0 24px;
    margin: 100px 24px 24px;
  }
}

.backButton {
  margin-top: 24px;
  margin-bottom: 24px;
  flex-shrink: 0;
  background: var(--marketplaceColor);
  border: none;
  color: var(--lightSecondary);

  &:hover {
    color: var(--attentionColor);
    border: 2px solid var(--attentionColor);
    background-color: rgba(40, 90, 200, .2);
    text-decoration: none;
  }


  @media (--viewportLarge) {
    display: inline-block;
    width: fit-content;
    padding: 0 24px;
    margin-top: 100px;
  }
}

.thumbnail {
  margin: 24px 0 0 0;

  @media (--viewportLarge) {
    width: calc(33.33% - 24px);
    margin: 0 24px 24px 0;
  }
}

.addImageWrapper {
  float: left;
  position: relative;
  width: 100%;
  margin: 24px 0 0 0;
  overflow: hidden;
  background-color: var(--marketplaceColor);

  &::after {
    content: '.';
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }

  @media (--viewportLarge) {
    width: calc(33.33% - 24px);
    margin: 0 24px 24px 0;
  }
}

.addImageWrapperDisabled {
  display: none;
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectRatioWrapper {
  padding-bottom: calc(100% * (3 / 5));
}

.addImage {
  /* Layout */
  /* Maintain aspect ratio */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;

  /* Colors */
  background-color: var(--marketplaceColor);

  @media (--viewportLarge) {
    background-color: var(--marketplaceColor);
  }

  border-style: dashed;
  border-color: var(--matterColorNegative);
  border-width: 2px;
  border-radius: 15px;

  /* Behaviour */
  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    border-color: var(--matterColorAnti);
  }
}

.chooseImageText {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chooseImage {
  composes: h4 from global;
  background-color: var(--matterColorBright);
  border-radius: 10px;
  color: var(--matterColor);
  font-weight: var(--fontWeightSemiBold);

  margin-top: 0;
  margin-bottom: 0;
  padding: 12px 24px;

  @media (--viewportLarge) {
    font-weight: var(--fontWeightSemiBold);
    margin-top: 0;
    margin-bottom: 0;
  }
}

.chooseImageTwo {
  composes: h4 from global;
  background-color: var(--attentionColor);
  border-radius: 50px;
  color: var(--matterColorBright);
  fill: var(--matterColorBright);
  font-weight: var(--fontWeightSemiBold);

  margin-top: 0;
  margin-bottom: 0;
  padding: 24px;

  @media (--viewportLarge) {
    font-weight: var(--fontWeightSemiBold);
    margin-top: 0;
    margin-bottom: 0;
  }
}

.addIcon {
  display: flex;
  width: 12px;
  height: 12px;
  transform: scale(1.5);
  color: var(--matterColorBright);
  fill: var(--matterColorBright);
}

.imageTypes {
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.addImageInput {
  display: none;
}

.imageRequiredWrapper {
  width: 100%;
  clear: both;
}



@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loaderWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;

  background-color: rgba(0, 0, 0, .8);

  z-index: 1000;
}

.loader {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 5px solid var(--lightSecondary);
  border-top: 5px solid var(--attentionColor);
  animation: spin 1s linear infinite;
}