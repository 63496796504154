@import '../../styles/customMediaQueries.css';

.root{
  display: flex;
  flex-direction: column-reverse;
  width: calc(100% - 48px);
  height: auto;
  padding: 48px 24px 48px 24px;
  background-color: var(--attentionColor);
  border-radius: 15px;
  margin: 15% auto 15% auto;

  @media (--viewportLarge) {
    flex-direction: row;
    margin: 96px 24px 96px 24px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 0 32px 0;

  @media (--viewportLarge) {
    justify-content: center;
    width: 50%;
    margin: 0 0 24px 40px;

  }
}

.title {
  composes: h2 from global;
  color: var(--matterColorBright);
  margin-top: 32px;

  @media (--viewportLarge) {
    margin-top: 0;
  }
}

.title span{
  color:var(--successColor);
}

.list{
  margin: 24px 0;
  padding: 0 0 0 24px;
  list-style-type: disc;

  @media (--viewportLarge) {
    margin: 32px 0;
    padding: 0 0 0 24px;
  }
}

.list li{
  font-family: var(--fontFamilyRegular);
  font-size: 16px;
  line-height: 24px;
  color: white;

  @media (--viewportLarge) {
    font-size: 20px;
    line-height: 28px;
  }
}

.imageContainer{
  display: flex;
  padding: 0;
  width: 100%;

  @media (--viewportLarge) {
    width: 50%;
    margin: 0 40px 0 0;
  }
}

.image{
  margin: auto;
  width: 100%;
  border-radius: 20px;
  box-shadow: -10px 40px 20px rgba(0, 0, 0, 0.3);
}

.customButtonElement {
  font-family: var(--fontFamilyMedium);
  font-size: 18px;
  background: var(--successColor);
  border-radius: 10px;
  border: none;
  color: white;
  padding: 16px 24px;
  width: fit-content;
  margin: 21px 0 0;
}

.customButtonElement:hover {
  text-decoration: none;
  filter: brightness(1.2);
}
