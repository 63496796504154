@import '../../../../styles/customMediaQueries.css';

.root {
}

.media {
  width: 100%;
  background-color: var(--matterColorNegative); /* Loading state color for the images */
  border-radius: 8px;
  margin-bottom: 0;
}

.text {
  width: 100%;
  margin-top: 20px;

  &:first-child {
    margin-top: 0;
  }

  & > hr {
    margin: 24px 0 0;
  }
}

.cta {
    margin-top: 24px;
    margin-bottom: 0;

    & > p {   
        color: var(--inputColor);
        font-size: 14px;

        @media (--viewportMedium) {
            font-size: 16px;
        }
    }
}

.title {
    margin: 16px 0!important;
}