@import '../../../../styles/customMediaQueries.css';

.root {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100vw;
  position: relative;

  &:nth-of-type(odd) {
    background-color: #fafafa;
  }

  &:last-child {
    padding-bottom: 64px;
  }

  
  &:first-child .sectionContent{
    padding-top: 0!important;
  }
}

.sectionContent {
  padding: 32px 0;
  position: relative;

  @media (--viewportMedium) {
    padding: 48px 0 0;
  }
}

.backgroundImageWrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
