
@import '../../styles/customMediaQueries.css';

.mtc{
  width: 100%;
  height: 100%;
  /* padding: 12% 0 12% 0; */
  display: flex;
}
.sectionTitle{
  line-height: 48px;
  margin: 48px 0 16px 0;
  composes: h1 from global;
  text-align: center;
  color: var(--marketplaceColorLight);
  font-family: var(--fontFamilyMedium);
}
.mtcLeftSide{
  width: 100%;
  text-align: center;
  color: black;
}
.mtcRightSide{
  width: 75%;
}
/* Animation for client coments */
.firstCarousel {
  display: -webkit-box;
  -webkit-box-pack: center;
  -webkit-box-align: center;
  box-sizing: border-box;
  width: 100%;
  height: 250px;
}

.firstCarousel .carouselCard{
  font-family: 'HumanSans-Bold', Arial, Helvetica, sans-serif;
  animation: community-animate 20s linear infinite;
  animation-duration: 20s;
}

@keyframes community-animate {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}


.firstCarousel div:rth-child(2) {
  animation: community-animate-two 10s linear infinite;
  animation-delay: -30s;


}

@keyframes community-animate-two {
  0% {
    transform: translateX(20%);
  }
  100% {
    transform: translateX(-150%);
  }
}



.secondCarousel {
  display: -webkit-box;
  -webkit-box-pack: center;
  -webkit-box-align: center;
  box-sizing: border-box;
  width: 100%;
  height: 250px;
}

.secondCarousel .carouselCard{
  font-family: 'HumanSans-Bold', Arial, Helvetica, sans-serif;
  animation: second-card-animation 30s linear infinite;
  animation-duration: 30s;
}

@keyframes second-card-animation {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}


.secondCarousel div:rth-child(2) {
  animation: second-card-animation-two 10s linear infinite;
  animation-delay: -30s;


}

@keyframes second-card-animation-two {
  0% {
    transform: translateX(20%);
  }
  100% {
    transform: translateX(150%);
  }
}


.thirdCarousel {
  display: -webkit-box;
  -webkit-box-pack: center;
  -webkit-box-align: center;
  box-sizing: border-box;
  width: 100%;
  height: 250px;
}

.thirdCarousel .carouselCard{
  font-family: 'HumanSans-Bold', Arial, Helvetica, sans-serif;
  animation: third-card-animation 30s linear infinite;
  animation-duration: 30s;
}

@keyframes third-card-animation {
  0% {
    transform: translateX(60%);
  }
  100% {
    transform: translateX(-110%);
  }
}


.thirdCarousel div:rth-child(2) {
  animation: third-card-animation-two 10s linear infinite;
  animation-delay: -30s;


}

@keyframes third-card-animation-two {
  0% {
    transform: translateX(20%);
  }
  100% {
    transform: translateX(150%);
  }
}




.carouselCard {
  box-sizing: content-box;
  margin: 0 1% 0 1%;
  display: flex;
  border-radius: 15px;
  background-color: var(--attentionColor);
  height: 80%;
  width: 900px;
}

.cardContent{
  height: 100%;
  width: 75%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.cardContentText{
  width: 80%;
  height: 80%;
  text-align: left;
  color: white;
  font-family: var(--fontFamilyRegular);;
  font-weight: 400;
  font-size: 16px;
}

.cardProfilePhoto{
  width: 20%;
  height: 100%;
  background-size: cover;
  border-radius: 10px 0 0 10px;
}

.cardProfilePhotoTwo{
  width: 20%;
  height: 100%;
  background-size: cover;
  border-radius: 10px 0 0 10px;
}

