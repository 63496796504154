@import '../../styles/customMediaQueries.css';

.popupSize {
  padding: 0 10px 7px 10px;
}

.dateRangeInput {
  & :global(.DayPicker__hidden) {
    visibility: visible;
  }

  & :global(.CalendarDay__default) {
    cursor: pointer;
  }

  & :global(.DayPicker_transitionContainer__horizontal) {
    min-height: 325px;
  }
}

.mobile {
  display: block;

  @media (--viewportLarge) {
    display: none;
  }
}

.desktop {
  display: none;

  @media (--viewportLarge) {
    display: block;
  }
}

@media (--viewportMedium) {
  .mobile {
    display: none;
  }

  .desktop {
    display: block;
  }
}