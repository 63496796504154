@import '../../styles/customMediaQueries.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;

  padding-top: 1px;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.formMargins {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.title {
  composes: formMargins;
}

.holder {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 36px;

  @media (--viewportMedium) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.half {
  composes: formMargins;
  width: 100%;

  position: relative;

  @media (--viewportMedium) {
    width: calc(100%);
  }
}

.half::after {
  content: 'Mbps';
  color: var(--inputColor);
  display: block;
  width: fit-content;
  position: absolute;
  right: 0;
  top: calc(25% - 8px);
}

.input {
  color: var(--matterColorBright);
  font-weight: 600;
}

.description {
  composes: formMargins;
  flex-shrink: 0;
}

.label {
  font-weight: var(--fontWeightSemiBold);
  margin: 0 0 36px ;
}

.tip {
  margin-top: 4px;
  font-size: 12px;
  color: var(--inputColor);
}

.icon {
  margin: 0 8px 0 0;
}

.tooltipDescription {
  display: flex;
  flex-direction: row;
  font-size: 16px;
  color: var(--matterColorDark);
}

.toolTipIcon {
  padding: 6px 0;
  height: auto;
  margin: auto 8px auto 0;
  min-width: 25%;
}

.tooltip {
  font-size: 16px;
  color: var(--lightSecondary);

  position: relative;
  z-index: 5;
}

.step {
  margin-right: 24px;
}

.description {
  margin: 8px 0;
}

.link {
  font-size: 16px;
  color: var(--successColor);

  &:hover {
    text-decoration: underline;
  }

  @media (--viewportMedium) {
    font-size: 20px;
  }
}

.emphasis {
  color: var(--successColor);
}

.tooltipContainer {
  display: none;
  color: var(--marketplaceColor);
}

.tooltipContainerActive {
  display: flex;
  flex-direction: column;
  background: var(--matterColorBright);
  border-radius: 15px;
  align-items: center;
  gap: 8px;
  position: absolute;
  top: 50%;
  right: 0;
  padding: 24px;
  width: calc(100vw - 72px);

  @media (--viewportMedium) {
    width: 500px;
  }
}

.tooltipHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: var(--attentionColor);
  width: 100%;
}


.buttonWrapper {
  display: flex;
  flex-direction: column;


  @media (--viewportLarge) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: fit-content;
    padding: 0 24px;
    margin-top: 100px;
    margin-left: auto;
  }
}

.listingButton {
  margin: 0 0 24px 0;
  flex-shrink: 0;
  background: var(--marketplaceColor);
  border: 1px solid var(--lightSecondary);
  color: var(--lightSecondary);

  &:hover,
  &:focus {
    color: var(--attentionColor);
    border: 2px solid var(--attentionColor);
    background-color: rgba(40, 90, 200, .2);
    text-decoration: none;
  }

  @media (--viewportLarge) {
    display: inline-block;
    width: fit-content;
    padding: 0 24px;
    margin: 100px 24px 24px;
  }
}

.backButton {
  margin-top: 24px;
  margin-bottom: 24px;
  flex-shrink: 0;
  background: var(--marketplaceColor);
  border: none;
  color: var(--lightSecondary);

  &:hover {
    color: var(--attentionColor);
    border: 2px solid var(--attentionColor);
    background-color: rgba(40, 90, 200, .2);
    text-decoration: none;
  }


  @media (--viewportLarge) {
    display: inline-block;
    width: fit-content;
    padding: 0 24px;
    margin-top: 100px;
  }
}