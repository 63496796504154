@import '../../../../styles/customMediaQueries.css';

.ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;

  & .ul {
    list-style-type: circle;

    & .ul {
      list-style-type: square;
    }
  }
}

.ol {
  display: block;
  list-style-type: decimal;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;

  & .ol {
    list-style-type: lower-alpha;

    & .ol {
      list-style-type: lower-roman;
    }
  }
}

/* Handle margin-top of next adjacent element against this list element */
.ul,
.ol {
  & + p,
  & + a,
  & + ul,
  & + ol,
  & + code,
  & + div,
  & + h1,
  & + h2,
  & + h3,
  & + h4,
  & + h5,
  & + h6 {
    margin-top: 8px;
  }
}

.li {
  font-size: 16px;
  margin: 6px 0;

  @media (--viewportMedium) {
    font-size: 20px;
  }
}

.li > p {
  margin: 0;
}
