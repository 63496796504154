@import '../../styles/customMediaQueries.css';

.root {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--matterColorBright);
  position: relative;
}

.filtersWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  
  max-height: 80vh;
  overflow: auto;
}

.footer {
  display: flex;
  margin-top: 8px;

  position: absolute;
  bottom: 0;
  left: 0;
  background-color: var(--matterColorBright);

  width: 100%;
}

.filterButton {
  /* Position and dimensions */
  display: inline;

  /* Borders */
  border: none;
  border-radius: 10px;

  /* Text size should be inherited */
  text-decoration: none;

  font-weight: var(--fontWeightMedium);
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0;
  /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px;

  /* Layout */
  padding: 16PX 24px;

  /* Override button styles */
  outline: none;
  width: fit-content;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 10px;
    margin-bottom: 14px;
  }
}
.resetAllButton {
  composes: filterButton;
  color: var(--attentionColor);
  border: 1px solid var(--attentionColor);

  /* Override button styles */
  outline: none;
  margin: 0 16px 0 auto;

  &:focus,
  &:hover {
    color: var(--matterColor);
  }
}

.cancelButton {
  composes: filterButton;
  color: var(--matterColorAnti);

  /* Layout */
  margin: 0 24px 0 24px;

  /* Override button styles */
  outline: none;

  &:focus,
  &:hover {
    color: var(--matterColor);
  }
}

.applyButton {
  composes: filterButton;
  background-color: var(--successColor);
  color: var(--matterColorBright);

  /* Layout */
  margin: 0;

  /* Override button styles */
  outline: none;

  &:focus,
  &:hover {
    color: var(--marketplaceColorDark);
  }
}
