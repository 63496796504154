@import '../../../../styles/customMediaQueries.css';

.root {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    gap: 16px;

    background-color: var(--matterColorBright);
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
  
    padding: 40px;
  
}

.media {
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (--viewportLarge) {
        width: 25%;
    }
}

.text {
    text-align: center;
}