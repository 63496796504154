@import '../../styles/customMediaQueries.css';

.root {
      display: block;
    width: 90vw;
    height: 450px;
    overflow: hidden;
    margin: 0 8px;

  @media (--viewportMedium) {
    display: block;
    width: 450px;
    height: 550px;
    overflow: hidden;
  }

  @media (--viewportLarge) {
    display: flex;
    position: relative;
    width: auto;
    height: auto;
    margin: 16px;
    max-width: 1000px;
    min-height: 250px;
  }
}

.media {
  width: 50%;
  margin: auto;
  margin-top: 20px;

 @media (--viewportLarge) {
  width: 30%;
  margin: 0;
  background-color: var(--matterColorNegative); /* Loading state color for the images */
  border-radius: 8px;
 }
}

.text {
  display: flex;
  margin: 0;
  flex-direction: column-reverse;
  justify-content: flex-end;
  font-size: 12px;
  padding: 16px;

  @media (--viewportLarge) {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    width: 70%;
    padding: 16px 32px 16px 32px;
    font-family: var(--fontFamilyMedium);
    /* color: var(--matterColorNegative); */
    color: #0A195A;
    font-size: 16px;

    &:first-child {
      margin-top: 0;
    }
  }
}

.title {
 font-size: 16px;
 @media (--viewportLarge) {
  margin-top: 15px;
 }
}

.blockQuotes {
  position: absolute;
  top: 8px;
  right: 8px;
  display: none;

  @media (--viewportLarge) {
    top: calc(50% - 30px);
    left: calc(30% - 22.5px);
  }
}




