@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--marketplaceColor);
  width: 100%;

  @media (--viewportLargeWithPaddings) {
    flex-direction: row;
    justify-content: space-between;
    position: relative;
  }
}

.imageContainer {
  display: none;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 20%;
    border-radius: 0 20px 20px 0;
  }

  @media (--viewportLarge) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 100%;
    border-radius: 0 20px 20px 0;
  }
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.formContainer {
  display: flex;
  flex-direction: column;
  background-color: var(--marketplaceColor);
  border-radius: 20px 0 0 20px;
  width: 100%;
  padding: 24px 16px;

  @media (--viewportLargeWithPaddings) {
    width: 52%;
    height: 100%;
    padding: 32px 24px;
    position: absolute;
    top: 0;
    right: 0;
  }
}

.title {
  composes: marketplaceHeroTitleFontStyles from global;
  font-family: var(--fontFamilyMedium);
  color: #fff;
  font-weight: 600;

  @media (--viewportMedium) {
    margin-top: 0;
  }
  @media (max-width: 768px) {
    display: none;
  }
}

.mobile_title {
  composes: marketplaceHeroTitleFontStyles from global;
  font-family: var(--fontFamilyMedium);
  color: #fff;
  font-weight: 600;

  @media (--viewportMedium) {
    margin-top: 0;
    display: none;
  }

  @media (--viewportSmall) {
    margin-top: 0;
  }
}

.form {
  margin: auto 0;
  padding: 0 24px;
  width: 100%;

  @media (--viewportMedium) {
    margin: 0 auto;
    width: 75%;
  }
}

.name {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
}

.firstNameRoot {
  width: calc(50% - 9px);
}

.lastNameRoot {
  width: calc(50% - 9px);
}

.email {
  margin-top: 24px;
}

.password {
  composes: marketplaceModalPasswordMargins from global;
  margin-top: 24px;
}

.bottomWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 24px;

  @media (--viewportMedium) {
    justify-content: flex-start;
    flex-direction: row;
  }
}

.socialButtonsOr {
  height: 32px;
  margin: auto 0 auto 0;
  text-align: center;
  position: relative;
}

@media (--viewportMedium) {
  .socialButtonsOr {
    height: 34px;
  }
}

.socialButtonsOrText {
  color: var(--secondaryMarketplaceColor);
  font-size: 16px;
  width: auto;
  display: inline-block;
  z-index: 3;
  padding: 0 20px 0 20px;
  position: relative;
  margin: 0;
}

.bottomWrapperText {
  composes: marketplaceModalBottomWrapperText from global;
  color: var(--secondaryMarketplaceColor);
  font-size: 14px;

  text-align: center;
  margin: 24px;

  @media (--viewportMedium) {
    margin: auto 24px;
  }
}

.loginLink {
  color: var(--secondaryMarketplaceColor);
  font-size: 14px;
  font-weight: 500;
  margin-left: 4px;
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
}

.buttoncustom {
  background: var(--matterColorBright);
  color: var(--matterColorDark);
  padding: 0 24px;
  min-height: 50px;
  width: 100%;
  margin: 0;
  font-size: 18px;

  &:disabled {
    background: var(--matterColorBright);
    color: var(--matterColorDark);
    cursor: not-allowed;
  }

  @media (--viewportMedium) {
    width: fit-content;
  }
}

.termsText {
  composes: marketplaceModalHelperText from global;
}

.termsLink {
  composes: marketplaceModalHelperLink from global;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.error {
  font-size: 14px;
  color: var(--failColor);
}

.green {
  font-size: 16px;
  font-weight: bold;
  color: var(--successColor);
}

.socialLoginButtons {
  position: relative;
  width: fit-content;

  margin: 24px 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.asset {
  position: absolute;
  bottom: -20px;
  left: 100%;
  width: 75px;
  display: none;

  @media (--viewportMedium) {
    display: block;
  }
}

.divider {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 40px 0;
  background-color: #E6FFFF1F;
  color: #E6FFFF1F;
  border-color: #E6FFFF1F;
}

.second {
  color: var(--light-blue, #E6FFFF);
  font-size: 16px;
  font-weight: 700;
  line-height: 26px; /* 162.5% */
}