
.label {
  font-weight: var(--fontWeightMedium);
}

.input {
  color: var(--inputColor);
  border-bottom-color: var(--inputColor);
}

.inputSuccess {
  border-bottom-color: var(--successColor);
}

.inputError {
  border-bottom-color: var(--failColor);
}
