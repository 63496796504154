@import '../../styles/customMediaQueries.css';

.root {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: auto;
}

.content {
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* 113 for footer and an extra 67 to get scrolling work in mobile safari */
  margin-bottom: 180px;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 24px;
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadowTop);
}

.logo {
  width: 75%;
  margin-bottom: 30%;
  /* margin-bottom: auto; */
  margin-top: calc(var(--TopbarMobileMenu_topMargin) - 60px);
}

.avatar {
  /* Layout */
  flex-shrink: 0;
  margin: var(--TopbarMobileMenu_topMargin) 0 0 0;
}

.greeting {
  /* Font */
  /* composes: h1 from global; */
  font-size: 32px;
  font-family: var(--fontFamilyMedium);
  margin-bottom: 1px;
  margin-top: 16px;
}

.logoutButton {
  /* Position and dimensions */
  display: inline;

  /* Borders */
  border: none;

  /* Text size should be inherited */
  text-decoration: none;

  /* Logout font is smaller and gray since the action is not recommended. */
  font-weight: var(--fontWeightSemiBold);
  font-size: 18px;
  line-height: 24px;

  color: var(--matterColorAnti);

  /* Position component */
  width: initial;
  margin: 4px 0 6px 0;
  padding: 0;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 16px;
    margin-bottom: 16px;
  }
}

.inbox {
  /* Font */
  composes: h3 from global;
  color: var(--marketplaceColor);
  position: relative;

  margin-top: auto;
  margin-bottom: 13px;
}

.navigationLink {
  /* Font */
  composes: h3 from global;
  color: var(--marketplaceColor);

  /* Layout */
  margin-top: 0;
  margin-bottom: 11px;
}
.currentPageLink {
  color: var(--marketplaceColorDark);
}

.notificationBadge {
  position: absolute;
  top: 4px;
  right: -26px;

  /* This is inside a link - remove underline */
  &:hover {
    text-decoration: none;
  }
}

.createNewListingLink {
  composes: button buttonFont buttonText marketplaceButtonBorders buttonColors from global;
  border-radius: 12px;

}

.authenticationGreeting {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* Font */
  font-size: 32px;
  line-height: 40px;
  font-weight: var(--fontWeightSemiBold);
  height: 100%;
}

.menuLin {
  /* Font */
  composes: h3 from global;
  color: var(--marketplaceColor);
  margin-top: 0;
  margin-bottom: 11px;
}

.authenticationLinks {
  white-space: nowrap;
}

.signupLink {
  text-decoration: none;
  white-space: nowrap;
  color: var(--successColor);
}
.loginLink {
  text-decoration: none;
  white-space: nowrap;
  color: var(--successColor);
}

.currentPage {
  color: var(--matterColorDark);

  /* black left border */
  border-left: 5px solid black;
  margin-left: -24px;
  padding-left: 19px;
}
