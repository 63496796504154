@import '../../styles/customMediaQueries.css';

.root {
  position: relative;
}

.label {
  font-weight: var(--fontWeightMedium);
  font-size: 16px;
}

.input {
  border-bottom-color: var(--inputColor);
  color: var(--matterColor);

  &::placeholder {
    color: var(--inputColor);
  }
}

.inputSuccess {
  border-bottom-color: var(--successColor);
}

.inputError {
  border-bottom-color: var(--failColor);
}

.textarea {
}

.wordCount {
  position: absolute;
  top: 23px;
  right: 0;
  font-size: 16px;
  color: var(--inputColor);
  background-color: var(--marketplaceColor);
  padding-left: 8px;
  font-style: italic;

  @media (--viewportMedium) {
    top: 28px;
  }
}

.wordCountBottom {
  position: absolute;
  bottom: 10px  ;
  right: 0;
  font-size: 16px;
  color: var(--inputColor);
  background-color: var(--marketplaceColor);
  padding-left: 8px;
  font-style: italic;
}

.labelGreen {
  color: var(--successColor);
}

.helperText {
  margin-top: 4px;
  font-size: 12px;
  color: var(--inputColor);
}