@import '../../../../styles/customMediaQueries.css';

.root {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background: var(--matterColorBright);

    height: 100%;
    padding: 40px;
    margin: 0 0 32px;
    width: 100%;

    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);

    @media (--viewportLarge) {
        width: 60%!important;
    }
}

.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;

    margin-bottom: 24px;
    width: 100%;
}

.media {
    display: flex;
    align-items: center;
    justify-content: center;

    margin-right: 24px;
    width: 60px;
}

.text {
    text-align: left;
}