@import '../../styles/customMediaQueries.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.priceInput {
  flex-shrink: 0;
  width: 100%;
  position: relative;
  margin: 0 0 24px;
}

.input {
  color: var(--matterColorBright)!important;
  font-weight: 600;
  color-scheme: dark;

  &::placeholder {
    font-weight: 500;
  }
}

.inputHolder {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
}

.longTermDiscount {
  justify-content: flex-start;
  margin-top: 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
    margin-bottom: 32px;
    width: calc(50% - 24px);
  }
}

.dLabel {
  font-size: 16px;
  font-weight: 500;
  margin: auto 0;

  @media (--viewportMedium) {
    width: calc(50% - 24px);
  }
}

.holder {
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (--viewportMedium) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.half {
  width: 100%;

  @media (--viewportMedium) {
    width: calc(33.33% - 24px);
  }
}

.priceInput::after {
  content: 'USD';
  color: var(--inputColor);
  display: block;
  width: fit-content;
  position: absolute;
  right: 0;
  top: calc(25% - 2px);
}

.discountInput {
  position: relative;
  margin-top: 24px;
  margin-bottom: 24px;

  width: 50%;

  @media (--viewportMedium) {
    width: calc(33.33% - 24px);
  }
}

.discountInput::after {
  content: '%';
  color: var(--inputColor);
  display: block;
  width: fit-content;
  position: absolute;
  right: 0;
  top: 4px;
}

.buttonWrapper {
  display: flex;
  flex-direction: column;
  

  @media (--viewportLarge) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: fit-content;
    padding: 0 24px;
    margin-top: 100px;
    margin-left: auto;
  }
}

.listingButton {
  margin: 0 0 24px 0;
  flex-shrink: 0;
  background: var(--marketplaceColor);
  border: 1px solid var(--lightSecondary);
  color: var(--lightSecondary);

  &:hover,
  &:focus {
    color: var(--attentionColor);
    border: 2px solid var(--attentionColor);
    background-color: rgba(40, 90, 200, .2);
    text-decoration: none;
  }

  @media (--viewportLarge) {
    display: inline-block;
    width: fit-content;
    padding: 0 24px;
    margin: 100px 24px 24px;
  }
}

.backButton {
  margin-top: 24px;
  margin-bottom: 24px;
  flex-shrink: 0;
  background: var(--marketplaceColor);
  border: none;
  color: var(--lightSecondary);

  &:hover {
    color: var(--attentionColor);
    border: 2px solid var(--attentionColor);
    background-color: rgba(40, 90, 200, .2);
    text-decoration: none;
  }


  @media (--viewportLarge) {
    display: inline-block;
    width: fit-content;
    padding: 0 24px;
    margin-top: 100px;
  }
}

.tooltip {
  font-size: 16px;
  color: var(--lightSecondary);
  margin: 16px 0 0;
  position: relative;
}

.tooltipOne {
  font-size: 16px;
  color: var(--lightSecondary);
  margin: 0 0 16px;
  position: relative;
}

.tooltipTitle {
  font-size: 16px;
  color: var(--lightSecondary);
  margin: 0;
  position: relative;
}

.tooltipContainer {
  display: none;
  color: var(--marketplaceColor);
}

.tooltipContainerActive {
  display: flex;
  flex-direction: column;
  background: var(--matterColorBright);
  border-radius: 15px;
  align-items: center;
  gap: 8px;
  position: absolute;
  top: 0;
  left: 0;
  padding: 24px;
  width: calc(100vw - 72px);
  z-index: 5;

  @media (--viewportMedium) {
    width: 500px;
  }
}

.tooltipHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: var(--attentionColor);

  width: 100%;
}

.icon {
  margin: 0 8px 0 0;
}

.tooltipContent {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.tooltipDescription {
  width: 100%;
}

.label {
  color: var(--successColor);
  margin: 0 0 36px ;
}

.onTop {
  display: flex;
  flex-direction: column;

  width: 50%;

  @media (--viewportMedium) {
    flex-direction: row;
    justify-content: space-between;
    width: 66.66%;
  }
}

.header {
  display: flex;
  flex-direction: column;
  margin: 24px 0;
}

.addCustomPricingButton {
  background: var(--marketplaceColor);
  border: none;
  color: var(--lightSecondary);
  text-decoration: underline;
  padding: 8px 0;
  margin: 0;
  width: fit-content;
}

.threeColumns {
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;

  position: relative;

  @media (--viewportMedium) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.close {
  position: absolute;
  top: 0;
  right: 0;
  color: var(--failColor);
  cursor: pointer;
}

.dateInput {
   /* Dimensions */
   display: block;
   width: 100%;
   margin: 0;
   padding: 5px 0 5px 0;
 
   /* Borders */
   border: none;
   border-bottom-width: 2px;
   border-bottom-style: solid;
   border-bottom-color: var(--marketplaceColor);
   border-radius: 0;
 
   &::placeholder {
     color: var(--matterColorAnti);
   }
 
   /* Effects */
 
   transition: border-bottom-color var(--transitionStyle);
 
   @media (--viewportMedium) {
     padding: 4px 0 2px 0;
   }
}