@import '../../../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
  padding: 24px;

  @media (--viewportMedium) {
    flex-direction: row-reverse;
    gap: 24px;   
    padding: 64px 100px;
  }
}

.media {
  width: 100%;
  background-color: var(--matterColorNegative); /* Loading state color for the images */
  border-radius: 8px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    width: 50%;
    margin-bottom: 0;
  }
}

.image {
  border-radius: 8px;
}

.text {
  width: 100%;
  margin-top: 20px;
  height: auto;
  margin: auto 0;

  &:first-child {
    margin-top: 0;
  }

  @media (--viewportMedium) {
    width: 50%;
  }
}

.text h2 {
  max-width: 70%;
}