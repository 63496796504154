.fullArea {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  & :global(.mapboxgl-ctrl-group) {
    margin: 20px 0 0 20px;
  }
}

.activeLabel {
  z-index: 1;
}

.labelContainer {
  &:hover {
    z-index: 1;
  }

  &:focus {
    outline: none;
  }
}

.infoCardContainer {
  z-index: 1;
}

.customNavigationControl {
  margin: 20px 0 0 40px!important;
}

.customNavigationControlButton {
  position: relative;
  background-color: var(--attentionColor)!important;
  color: var(--matterColorBright)!important;
}

/* white background-image */