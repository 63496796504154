@import '../../../../styles/customMediaQueries.css';

.root {
  flex-direction: column;
}

.media {
  width: 100%;
  background-color: var(--matterColorNegative); /* Loading state color for the images */
  border-radius: 8px;
  margin-bottom: 0;
}

.text {
  width: 100%;
  margin-top: 20px;

  &:first-child {
    margin-top: 0;
  }

  & > hr {
    margin: 24px 0 0;

    @media (--viewportMedium) {
      margin: 48px 0 0;
    }
  }
}
