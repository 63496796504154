@import '../../styles/customMediaQueries.css';

.root {
  flex-grow: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  background: var(--marketplaceColor);
}

.form {
  flex-grow: 1;
}


.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  height: 124px;
  margin-bottom: 24px;

  @media (--viewportLarge) {
    margin-bottom: 36px;
  }
}

.title {
  font-size: 24px;
  font-weight: var(--fontWeightMedium);
  margin: auto 0;

  @media (--viewportLarge) {
    margin: auto 0;
    padding: 1px 0 7px 0;
  }
}

.number {
  font-size: 62px;
  font-weight: var(--fontWeightBold);
  margin: auto 0;
  color: #FFFFFF15;
}

.emphasis {
  color: var(--successColor);
}

.link {
  color: var(--matterColorBright);
  font-weight: var(--fontWeightBold);
}