@import '../../../../styles/customMediaQueries.css';

.root {
    flex-direction: column;
    width: 100%;

    @media (--viewportMedium) {
        width: 100%!important;
    }
}

.media {
    width: 100%;
    background-color: var(--matterColorNegative);
    /* Loading state color for the images */
    border-radius: 8px;
    margin-bottom: 0;
}

.text {
    width: 100%;
    margin-top: 20px;

    &:first-child {
        margin-top: 0;
    }

    &>hr {
        margin: 24px 0 0;

        @media (--viewportMedium) {
            margin: 48px 0 0;
        }
    }
}

.modalContent {
    text-align: center;
}

.modal {
    flex-grow: 1;

    /* Create context so the close button can be positioned within it */
    position: relative;

    /* Display and dimensions */
    display: flex;
    flex-direction: column;
    padding: var(--modalPadding);

    background-color: var(--matterColorLight);
    border-radius: var(--borderRadius);
    border-bottom: none;

    min-height: 100vh;
    height: 100%;

    @media (--viewportMedium) {
      flex-basis: 70%;
      flex-grow: 0;
      min-height: auto;
      height: auto;

      padding: var(--modalPaddingMedium);
      margin-top: 12.5vh;
      margin-bottom: 12.5vh;
      border-bottom: 8px solid var(--marketplaceColor);
    }
}

.modalButton {
    border: 1px solid var(--marketplaceColor);
    padding: 0 24px;
    margin: 40px auto;
    min-width: 200px;
    width: fit-content;
}