.root {
}

.media {

 @media (--viewportLarge) {
    width: 25%;
    max-width: 30%;
    background-color: var(--matterColorNegative); /* Loading state color for the images */
    border-radius: 8px;
    margin-bottom: 0;
 }
}

.text {
 @media (--viewportLarge) {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    width: 100%;
    padding: 0 4% 0 3%;
    margin: 20px 0 0 -150px;
    font-family: var(--fontFamilyRegular);
    color: var(--matterColorNegative);
    /* color: #0A195A; */

    &:first-child {
      margin-top: 0;
    }
  }
}

.title {
 @media (--viewportLarge) {
    font-size: 24px;
    margin-top: 15px;
 }
}



.blockQuotes {
  width: 25%;
  height: 50%;
  margin: auto;
  display: flex;
  align-items: center;

}
.blockQuotes img {
  width: 70%;
  height: 70%;
  position: relative;
  right: 35%;
}

