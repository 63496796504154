@import '../../../../styles/customMediaQueries.css';

.p {
  font-size: 16px;
  line-height: 24px;
  margin-top: 0;
  margin-bottom: 0;
  letter-spacing: 0;
  white-space: break-spaces;
  /* Handle margin-top of next adjacent element against this p element */
  & + p,
  & + ul,
  & + ol,
  & + pre,
  & + div,
  & + h1,
  & + h2,
  & + h3,
  & + h4,
  & + h5,
  & + h6 {
    margin-top: 24px;
  }

  @media (--viewportMedium) {
    font-size: 20px;
    line-height: 32px;
  }
}

.greenText {
  color: var(--successColor);
}

.blueText {
  color: var(--attentionColor);
}

.whiteText {
  color: var(--matterColorBright);
}

.blackText {
  color: var(--matterColorDark);
}

.martketColorText {
  color: var(--marketplaceColor);
}