/* Specific styles */
.h1 {
  composes: h1 from global;
  color: var(--marketplaceColor);

  /* If ´& + *´ if used, margin-top needs !important to overwrite */
  /* Handle margin-top of next adjacent element against this heading element */
  & + p,
  & + a,
  & + ul,
  & + ol,
  & + code,
  & + div,
  & + h1,
  & + h2,
  & + h3,
  & + h4,
  & + h5,
  & + h6 {
    margin-top: 24px;
  }
}

.h2 {
  composes: h2 from global;
  color: var(--marketplaceColor);

  /* If ´& + *´ if used, margin-top needs !important to overwrite */
  /* Handle margin-top of next adjacent element against this heading element */
  & + p,
  & + a,
  & + ul,
  & + ol,
  & + code,
  & + div,
  & + h1,
  & + h2,
  & + h3,
  & + h4,
  & + h5,
  & + h6 {
    margin-top: 16px;
  }
}

.h3 {
  composes: h3 from global;
  color: var(--marketplaceColor);
}

.h4 {
  composes: h4 from global;
  color: var(--marketplaceColor);
}

.h3,
.h4,
.h5,
.h6 {
  /* If ´& + *´ if used, margin-top needs !important to overwrite */
  /* Handle margin-top of next adjacent element against this heading element */
  & + p,
  & + a,
  & + ul,
  & + ol,
  & + code,
  & + div,
  & + h1,
  & + h2,
  & + h3,
  & + h4,
  & + h5,
  & + h6 {
    margin-top: 8px;
  }
}

.greenText {
  color: var(--successColor);
}

.blueText {
  color: var(--attentionColor);
}

.whiteText {
  color: var(--matterColorBright);
}

.blackText {
  color: var(--matterColorDark);
}

.martketColorText {
  color: var(--marketplaceColor);
}

.left {
  text-align: left!important;
  justify-content: left;
}

.center {
  text-align: center!important;
  justify-content: center;
}

.right {
  text-align: right!important;
  justify-content: right;
}