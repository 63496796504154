@import '../../styles/customMediaQueries.css';

.root {
  /* Layout */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  /* Content of EditListingWizard should have smaller z-index than Topbar */
  z-index: 0;
}

.tabsContainer {
  flex-grow: 1;
  /* Layout */
  width: 100%;
  height: 100%;
  background-color: var(--lightSecondary);

  display: flex;
  flex-direction: column;

  @media (--viewportLarge) {
    flex-direction: row;
    justify-content: center;
  }
}

.nav {
  /* Layout */
  display: flex;
  flex-direction: row;
  padding: 0;
  flex-shrink: 0;
  background-color: var(--lightSecondary);
  box-shadow: var(--boxShadowLight);
  border-top: 1px solid var(--matterColorNegative);
  overflow-x: scroll;

  @media (--viewportLarge) {
    padding: 128px 36px 82px 0;
    flex-direction: column;
    overflow-x: auto;
    background-color: var(--lightSecondary);
    box-shadow: none;
    border-top: none;
  }

  @media (--viewportLargeWithPaddings) {
    padding: 128px calc((100% - 1056px) / 2) 82px 0;
  }
}

.tab {
  margin-left: 16px;
  white-space: nowrap;
  padding: 12px 0;

  &:first-child {
    margin-left: 0;

    /* Padding added to tab so that it will be visible after call to scrollToTab */
    padding-left: 24px;
  }

  &:last-child {
    /* Padding added to tab so that it will be visible after call to scrollToTab */
    padding-right: 24px;
  }

  @media (--viewportLarge) {
    padding: 0;
    margin-left: 0;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }
}

.panel {
  flex-grow: 1;
  color: var(--matterColorBright);
  background-color: var(--marketplaceColor);
  border-radius: 15px 15px 0 0;
  padding: 36px 36px 82px 36px;

  @media (--viewportLarge) {
    border-radius: 15px 0 0 15px;
    padding: 88px 36px 82px 59px;
    border-left: 1px solid var(--marketplaceColor);
  }

  @media (--viewportLargeWithPaddings) {
    padding: 60px 100px;
  }
}

.payoutDetails {
  margin-bottom: 100px;

  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}

.modalTitle {
  composes: marketplaceModalTitleStyles from global;
}

.modalPayoutDetailsWrapper {
  @media (--viewportMedium) {
    width: 604px;
    padding-top: 11px;
  }
}

.modalMessage {
  composes: marketplaceModalParagraphStyles from global;
}

.newListingCreated {
  display: flex;
  flex-direction: column;

  position: relative;
  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.imageContainer {
  display: none;

  @media (--viewportMedium) {
    display: flex;
    width: 50%;
  }
}

.firstContent {
  background-color: var(--attentionColor);
  border-radius: 0;
  padding: 24px;
  min-height: 100vh;
  @media (--viewportMedium) {
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 15px 0 0 15px;
    padding: 32px 100px;

    height: 100%;
    width: 52%;
  }
}

.title {
  composes: h3 from global;
  color: var(--matterColorBright);
  margin-bottom: 16px;

  @media (--viewportMedium) {
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    font-size: 45px;
    line-height: 64px;  }
}

.green {
  color: var(--successColor);
}

.steps {
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.step {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  color: var(--matterColorBright);
  font-size: 16px;
  line-height: 24px;
  margin: 8px 0;

  @media (--viewportMedium) {
    font-size: 20px;
    line-height: 32px;
    width: 50%;
  }
}

.check {
  width: 24px;
  height: 24px;
  margin-right: 16px;

  @media (--viewportMedium) {
    width: 32px;
    height: 32px;
  }
}

.button {
  background-color: var(--successColor);
  margin-top: 64px;
  padding: 12px 24px;
  width: fit-content;

  &:hover {
    background-color: var(--successColor);
  }
}