@import '../../../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: #E6FFFF;

  padding: 24px;
  margin: 0;

  @media (--viewportMedium) {
    padding: 64px;
  }
}

.media {
  width: 80px;
  background-color: var(--matterColorNegative); /* Loading state color for the images */
  border-radius: 50%;
  margin-bottom: 0;
}

.title {
  font-size: 28px;
  font-weight: 700;
  line-height: 32px;
  
  color: var(--marketplaceColor);

  @media (--viewportMedium) {
    font-size: 45px;
    font-weight: 700;
    line-height: 54px;
  }
}

.text {
  width: 100%;
  margin-top: 20px;
  color: var(--marketplaceColor);

  text-align: center;

  &:first-child {
    margin: auto;
  }
}

.dark {
  background: var(--matterColorDark);
  color: var(--matterColorBright);
}

.light {
  background: var(--matterColorLight);
}

.marketplace {
  background: var(--marketplaceColor);
  color: var(--matterColorBright);
}