.root {
  position: relative;
  display: inline-block;
}

.label {
  composes: button marketplaceSearchFilterLabelFontStyles buttonText buttonBordersSecondary buttonColorsSecondary from global;
  letter-spacing: 0.2px;

  padding: var(--marketplaceButtonSmallDesktopPadding);
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;

  &:focus {
    outline: none;
    background-color: var(--matterColorLight);
    border-color: transparent;
    text-decoration: none;
    box-shadow: var(--boxShadowFilterButton);
  }
}

.labelSelected {
  composes: button marketplaceSearchFilterLabelFontStyles buttonText buttonBorders buttonColors from global;
  letter-spacing: 0.2px;
  font-weight: var(--fontWeightSemiBold);

  padding: var(--marketplaceButtonSmallDesktopPadding);
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;
  border: 1px solid var(--marketplaceColor);

  &:hover,
  &:focus {
    border: 1px solid var(--marketplaceColorDark);
  }
}

.labelText {
  display: inline-block;
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.field {
  composes: h4 from global;
  margin: 0;
  padding: 1px 0 13px 0;
  border: none;
}

.fieldPlain {
  composes: h4 from global;
  margin: 0;
  padding: 16px 0 30px 20px;
  border: none;
}


.locationAutocompleteInput {
  flex-grow: 1;
  height: 36px;
  line-height: unset;
  padding-left: 0;
  margin: 0;
  border-bottom: 2px solid var(--inputColor);
  color: var(--matterColor);

  &:hover,
  &:focus {
    border-bottom-color: var(--matterColorDark);
    outline: none;
  }

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  &::placeholder {
    color: var(--inputColor);
    opacity: 1;
  }

  @media (--viewportMedium) {
    height: 40px;
  }
}

.locationAutocompleteInputIcon {
  display: none;
}

.predictionsRoot {
  position: absolute;
  width: 100%;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  top: 36px;
  left: 0;
  background: var(--secondaryMarketplaceColor);
  color: var(--marketplaceColor)!important;
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  & ul {
    & li {
      color: var(--marketplaceColor);

      &:hover,
      &.highlighted {
        cursor: pointer;
        border-left: 6px solid var(--marketplaceColor);
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        color: var(--marketplaceColor);
      }
    }
  }
  @media (--viewportMedium) {
    top: 40px;
  }
}

.predictionsRoot .predictions {
  & li {
    color: var(--marketplaceColor)!important;
    
  }
}

.validLocation {
  border-bottom-color: var(--successColor);
}

.locationAddress {
  flex-shrink: 0;
}