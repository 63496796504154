@import '../../styles/customMediaQueries.css';

.root {
  margin: 0;
}

.reviewItem {
  margin-bottom: 32px;

  &:last-of-type {
    margin-bottom: 0;
  }

  @media (--viewportMedium) {
    margin-bottom: 39px;
  }
}

.review {
  display: flex;
  flex-direction: column;
  flex-basis: 564px;
  flex-shrink: 1;
  flex-grow: 0;

  @media(--viewportMedium) {
    border-bottom: 1px solid var(--inputColor);
  }
}

.user {
  display: flex;
  flex-direction: row;
}

.avatar {
  flex-shrink: 0;
  margin-right: 8px;
}

.name {
  color: var(--matterColorDark);
  font-weight: 600;

  margin: auto 0;
  height: fit-content;
}

.desktopReviewDate {
  display: none;
  color: var(--inputColor);
  @media (--viewportMedium) {
    display: block;
    margin: auto 0;
    margin-left: auto;

    height: fit-content;
  }
}

.mobileReviewDate {
  color: var(--inputColor);
  margin-top: 3px;
  margin-bottom: 9px;

  @media (--viewportMedium) {
    display: none;
  }
}

.reviewContent {
  composes: h4 from global;
  margin: 24px 0;
  white-space: pre-line;
}

.reviewInfo {
  composes: h5 from global;
  margin-top: 10px;
  margin-bottom: 0px;
  color: var(--matterColorAnti);

  @media (--viewportMedium) {
    margin-top: 9px;
  }
}

.separator {
  margin: 0 7px;
}

.desktopSeparator {
  margin: 0 7px;
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.mobileReviewRating {
  display: block;
  margin-top: 3px;
  margin-bottom: 9px;

  @media (--viewportMedium) {
    display: none;
  }
}

.desktopReviewRatingWrapper {
  /* For aligning rating starts in the middle
   * of a paragraph line */
  position: relative;
  display: flex;

  @media (--viewportMedium) {
  }
}

.desktopReviewRating {
  /* For aligning rating starts in the middle
   * of a paragraph line */
  display: inline-flex;
  white-space: nowrap;
  margin: auto 24px;
}

.reviewRatingStar {
  height: 12px;
  width: 12px;
  margin-right: 2px;

  &:last-of-type {
    margin-right: 0;
  }
}

.seeMoreButton {
  background-color: var(--matterColorBright);
  border: 1px solid var(--attentionColor);
  border-radius: 10px;
  transition: var(--transitionStyleButton);
  font-family: var(--fontFamilyRegular);

  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 0;

  flex-shrink: 0;
  color: var(--attentionColor);
  margin: 0 auto;
  margin-top: 16px;
  margin-bottom: 16px;
  padding: 12px 18px;
  text-decoration: none;
  width: fit-content;

  &:hover {
    color: var(--attentionColor);
    border: 1px solid var(--attentionColor);
    background-color: rgba(40, 90, 200, .2);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    font-size: 18px;
    line-height: 16px;
  }
}

