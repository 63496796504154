@import '../../styles/customMediaQueries.css';

.logoMobile {
  width: 50%;
  & path {
    fill: var(--marketplaceColor);
  }

  @media (--viewportMedium) {
    width: 30%;
  }
}
