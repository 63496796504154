@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;

  background-color: var(--matterColorLight);
  border-radius: 16px;
  padding: 24px;

  width: 100%;

  @media (--viewportMedium) {
    padding: 64px;
  }
}

.doubleInput {
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (--viewportMedium) {
    flex-direction: row;
    gap: 48px;
  }
}

.field {
  background-color: var(--matterColorLight);
  color: var(--matterColorDark);
  margin: 25px 0 24px 0;

  width: 100%;

  & > input,
  > textarea {
    color: var(--matterColorDark);
  }

  @media (--viewportMedium) {
    margin: 22px 0 24px 0;
  }
}

.error {
  color: var(--failColor);
  margin-top: 0;

  @media (--viewportMedium) {
    margin-top: 0;
  }
}

.submitButton {
  margin-top: 24px;
  width: 100%;

  @media (--viewportMedium) {
    margin-top: 48px;
    padding: 0 24px;
    width: fit-content
  }
}

.success {
  color: var(--successColor);
  font-size: 16px;
  margin-top: 0;

  @media (--viewportMedium) {
    margin-top: 0;
  }
}

.error {
  color: var(--failColor);
  font-size: 16px;
  margin-top: 0;

  @media (--viewportMedium) {
    margin-top: 0;
  }
}