@import '../../styles/customMediaQueries.css';

.root {
  position: relative;

  width: 100%;
  padding: 24px;
  /* padding: 24px 24px 42px 24px; */
  background-color: var(--marketplaceColor);

  @media (--viewportMedium) {
    /* padding: 0 24px 33px 24px; */
  }

  @media (--viewportLarge) {
    border-top: none;
  }
}

/* Wrapper to limit the top border in desktop
 * view from the outermost paddings*/
.topBorderWrapper {
  display: none;
  background-color: #0A195A;
  @media (--viewportMedium) {
    display: block;
    /* padding-top: 41px; */
  }

  @media (--viewportLarge) {
    padding-top: 2%;
    padding-bottom: 1%;
  }
}

.mobileWrapper {
  display: flex;
  flex-direction: column;

  width: 100%;

  @media (--viewportMedium) {
    display: none;
  }
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (--viewportMedium) {
    margin: 0 auto;
  }

  @media (--viewportLarge) {
    width: 90%;
    /* max-width: 1128px; */
    padding: 0 36px;
  }

  @media (--viewportXLarge) {
    /* max-width: 1056px; */
    width: 90%;
    padding: 0;
  }
}

.icon {
  display: inline-block;
  width: 20px;
  height: 24px;
  margin-right: 12px;
  text-align: center;
}

/* Footer links */
.links {
  /* Layout */
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 18px;

  @media (--viewportMedium) {
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-bottom: 0;
  }
}

.mobileLinks {
  display: flex;
  flex-direction: column;
  color: var(--matterColorBright);
  width: 100%;
  margin-bottom: 18px;
}

/* Desktop: logo and organization stuff */
.organization {
  flex-shrink: 0;
  display: block;
  margin-right: 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    display: block;
    order: 1;
    margin-right: 12px;
  }
  @media (--viewportLarge) {
    flex-basis: 240px;
    margin-right: 57px;
  }
  @media (--viewportLargeWithPaddings) {
    flex-basis: 250px;
    margin-right: 12px;
  }
}

.logoLink {
  display: block;
  margin-bottom: 14px;
}

.logo_white {
  margin: 16px 32px 0 0;
  max-width: 160px;
  display: block;
  margin-bottom: 14px;
}

.logo {
  object-fit: contain;
  object-position: left center;
  width: 100%;
  height: 27px;
}

.organizationInfo {
}

.organizationDescription,
.organizationCopyright {
  composes: marketplaceTinyFontStyles from global;
}

.organizationCopyright {
  margin-top: 24px;
}

.copyrightLink {
  color: var(--matterColorNegative);
  transition: var(--transitionStyleButton);
  font-size: 16px;
  display: flex;
  margin-top: 36px;

  &:hover {
    color: var(--matterColorAnti);
    text-decoration: none;
  }
}

/* Desktop and Mobile: links to more information */
.infoLinks {
  order: 2;
  flex-basis: 165px;
  margin-right: 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    padding-top: 2px;
    margin-right: 12px;
  }
}

.list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media (--viewportMedium) {
    flex-direction: column;
  }

  margin-top: 0;
  margin-bottom: 0;
}

.infoLinks h3{
  font-family: var(--fontFamilyRegular);;
  color: var(--matterColorNegative);
  font-size: 24px;
}

.listItem {
  color: var(--matterColorBright);
  min-height: 24px;
  padding: 3% 0 3% 0;
  margin-bottom: 6px;
  width: 50%;

  @media (--viewportMedium) {
    margin-bottom: 0;
    width: 100%;
  }
}

.link {
  /* Font */
  composes: h5 from global;
  font-size: 16px;
  line-height: 20px;
  color: var(--matterColorNegative);
  transition: var(--transitionStyleButton);

  margin-top: 0;
  margin-bottom: 12px;

  &:hover {
    color: var(--successColor);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.linkContact {
  /* Font */
  composes: h5 from global;
  font-size: 16px;
  line-height: 20px;
  color: var(--successColor);
  transition: var(--transitionStyleButton);

  margin-top: 0;
  margin-bottom: 12px;

  &:hover {
    color: var(--successColor);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

/* Desktop and Mobile: searches - first column */
.searches {
  order: 3;
  flex-basis: 165px;
  margin-right: 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    padding-top: 2px;
    margin-right: 12px;
  }
}

/* Desktop and Mobile: searches - second column */
/* This is not always visible */
.searchesExtra {
  order: 4;
  flex-basis: 165px;
  margin-right: 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    padding-top: 2px;
    margin-right: 12px;
  }
}

/* Desktop: extra links (social media links and legal links) */
.extraLinks {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  flex-basis: 109px;
  margin-right: 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    order: 5;
    margin-right: 12px;
  }
  @media (--viewportLarge) {
    margin-right: 0;
  }
}

.extraLinks h3 {
  font-family: var(--fontFamilyRegular);;
  color: var(--matterColorNegative);
  font-size: 20px;
}

.legalMatters {
  /* Get extra space from parent element (which is using flexbox) */
  flex-grow: 1;

  /* Layout */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.tosAndPrivacy {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  margin-top: 0;
  margin-bottom: 0;

  height: 100%;
  @media (--viewportMedium) {
    margin-bottom: 10px;
  }
}

.tosAndPrivacy .listItem {
  width: 100%;
}

.legalLink,
.privacy,
.terms {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColorNegative);
  transition: var(--transitionStyleButton);

  margin-top: 0;
  margin-bottom: 0;

  &:hover {
    color: var(--matterColorAnti);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.extra {
  font-size: 14px;
  color: var(--matterColorBright);
}

.contact {
  color: var(--successColor);
}

.mailto {
  color: var(--successColor);
  text-decoration: underline;
}

/* Mobile: copyright mark and legal links for mobile */
.copyrightAndTermsMobile {
  /* Positioning */
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  /* Dimensions */
  height: 60px;
  padding: 0 24px 18px 24px;

  /* Layout */
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    display: none;
  }
}

.organizationCopyrightMobile {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColorAnti);
  transition: var(--transitionStyleButton);

  /* Tell parent component how much space this one prefers */
  flex-basis: 172px;

  margin-top: 0;
  margin-bottom: 0;

  &:hover {
    color: var(--matterColorAnti);
    text-decoration: none;
  }
}

.tosAndPrivacyMobile {
  flex-basis: 172px;
}

.privacy {
  margin-right: 24px;
}
