@import '../../styles/customMediaQueries.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  margin-right: 0!important;
  position: relative;
  
  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
    box-shadow: var(--boxShadowListingCard);
  }
}

.rootHorizontal {
  /* Layout */
  flex-direction: column;
  flex-wrap: wrap;
  padding: 0;
  margin: 36px 0 0;
  position: relative;

  @media (--viewportLarge) {
    flex-direction: row;
    margin: 0 0 24px;
  }

  
  @media (--viewportXLarge) {
    flex-direction: column;
    margin: 0 0 0;
  }
}

.favoriteButton {
  /* Layout */
  position: absolute;
  top: 16px;
  left: 16px;
  z-index: 0;

  background-color: var(--matterColorBright);
  border: 1px solid var(--matterColorBright);
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
  }
}

.favoriteIcon {
  fill: var(--matterColorBright);
  cursor: pointer;
}

.clicked {
  fill: var(--failColor);
  opacity: 0.75;
}

.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;
  transition: var(--transitionStyleButton);
}

.threeToTwoWrapperHorizontal {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;
  transition: var(--transitionStyleButton);

  @media (--viewportLarge) {
    width: 40%;
  }

  @media (--viewportXLarge) {
    width: 100%;
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  padding-bottom: 66.6%; /* 3:2 Aspect Ratio */
  background: var(--matterColorNegative); /* Loading BG color */
  border-radius: 15px 15px 0 0;
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapperHorizontal {
  padding-bottom: 66.6%; /* 3:2 Aspect Ratio */
  background: var(--matterColorNegative); /* Loading BG color */
  border-radius: 15px 15px 0 0;

  @media (--viewportLarge) {
    width: 40%;
  }

  @media (--viewportXLarge) {
    width: 100%;
  }
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  object-fit: cover;
  height: 100%;
  border-radius: 15px 15px 0 0;
}

.rootForImageHorizontal {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  object-fit: cover;
  height: 100%;
  border-radius: 15px 15px 0 0;

  
  @media (--viewportLarge) {
    border-radius: 15px 0 0 15px;
  }

  @media (--viewportXLarge) {
    border-radius: 15px 15px 0 0;
  }
}

.info {
  /* Layout */
  display: flex;
  flex-direction: column;
  background-color: var(--matterColorBright);
  padding: 24px;
  flex-grow: 1;
  border-radius: 0 0 15px 15px;
}

.infoHorizontal {
  /* Layout */
  display: flex;
  flex-direction: column;
  background-color: var(--matterColorBright);
  padding: 24px;
  flex-grow: 1;
  border-radius: 0 0 15px 15px;

  
  @media (--viewportLarge) {
    border-radius: 0 15px 15px 0;
    width: 60%;
  }

  @media (--viewportXLarge) {
    border-radius: 0 0 15px 15px;
    width: 100%;
  }
}

.headerInfo {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.type {
  display: inline-block;
}

.typeId {
  /* Font */
  font-size: 16px;
  color: var(--successColor);
  margin-left: 8px;
}

.price {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex-shrink: 0;
  margin-top: 16px;
}

.priceValue {
  /* Font */
  composes: h3 from global;
  color: var(--successColor);
  width: fit-content;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColor);
  font-size: 16px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 8px;
    margin-bottom: 0;
  }
}

.discounts {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.discount {
  font-size: 12px;
  line-height: 16px;
  color: var(--inputColor);
}

.mainInfo {
  display: flex;
  flex-direction: column;
}

.title {
  /* Font */
  composes: h3 from global;
  font-size: 20px;
  color: var(--attentionColor);

  /* Remove default margins from font */
  text-align: left;
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.location {
  font-size: 16px;
  color: var(--matterColor);
  text-align: left;
  width: 100%;
}

.amenities {
  /* Layout */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: auto;
  justify-content: space-between;

  @media (--viewportMedium) {
    justify-content: flex-start;
  }
}

.amenity {
  display: flex;
  flex-direction: row;
  color: #5A5A5A;
  font-size: 16px;
  margin: 0;

  @media (--viewportMedium) {
    margin-right: 24px;
  }
}

.amenityValue {
  margin:  auto 0 auto 4px;
}

.authorInfo {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}
