@import '../../styles/customMediaQueries.css';

.root {
  margin: 0;
  padding: 0;
  border: none;
}

.list {
  margin: 0;
}

.typedInput {
  display: flex;
  flex-direction: row;

  justify-content: space-between;
}

.inputRootClass {
  margin-left: auto;
  width: 100%;
}

.selectSuccess {
  color: var(--matterColorBright);
  font-weight: 600;
}

.twoColumns {
  @media (--viewportMedium) {
    columns: 2;
  }
}

.item {
  padding: 12px 0;

  /* Fix broken multi-column layout in Chrome */
  page-break-inside: avoid;

  @media (--viewportMedium) {
    margin: 12px 0;
    padding: 4px 0;
  }
}

.itemNotSelected {
  display: none;
}