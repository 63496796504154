@import '../../styles/customMediaQueries.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Display format */
  display: flex;
  flex: 1;
  flex-direction: column;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.label {
  color: var(--successColor);
  font-size: 16px;
  font-weight: var(--fontWeightSemiBold);
  margin: 0 0 36px ;
}

.locationAutocompleteInput {
  flex-grow: 1;
  height: 36px;
  line-height: unset;
  padding-left: 0;
  margin: 0;
  border-bottom: 2px solid var(--attentionColor);

  &:hover,
  &:focus {
    border-bottom-color: var(--matterColorDark);
    outline: none;
  }

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  @media (--viewportMedium) {
    height: 40px;
  }
}

.locationAutocompleteInputIcon {
  display: none;
}

.predictionsRoot {
  position: absolute;
  width: 100%;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  top: 36px;
  left: 0;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  @media (--viewportMedium) {
    top: 40px;
  }
}

.validLocation {
  border-bottom-color: var(--successColor);
}

.locationAddress,
.building {
  flex-shrink: 0;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.buttonWrapper {
  display: flex;
  flex-direction: column;
  

  @media (--viewportLarge) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: fit-content;
    padding: 0 24px;
    margin-top: 100px;
    margin-left: auto;
  }
}

.listingButton {
  margin: 0 0 24px 0;
  flex-shrink: 0;
  background: var(--marketplaceColor);
  border: 1px solid var(--lightSecondary);
  color: var(--lightSecondary);

  &:hover,
  &:focus {
    color: var(--attentionColor);
    border: 2px solid var(--attentionColor);
    background-color: rgba(40, 90, 200, .2);
    text-decoration: none;
  }

  @media (--viewportLarge) {
    display: inline-block;
    width: fit-content;
    padding: 0 24px;
    margin: 100px 24px 24px;
  }
}

.backButton {
  margin-top: 24px;
  margin-bottom: 24px;
  flex-shrink: 0;
  background: var(--marketplaceColor);
  border: none;
  color: var(--lightSecondary);

  &:hover {
    color: var(--attentionColor);
    border: 2px solid var(--attentionColor);
    background-color: rgba(40, 90, 200, .2);
    text-decoration: none;
  }


  @media (--viewportLarge) {
    display: inline-block;
    width: fit-content;
    padding: 0 24px;
    margin-top: 100px;
  }
}

.sections {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  width: 100%;
}

.section {
  padding: 8px 0;
  width: 50%;
}

.link {
  display: flex;
  flex-direction: row;
  color: var(--matterColorBright);
  text-decoration: underline;
}

.check {
  margin: auto 12px auto 0;
  height: 20px;
  width: 20px;
}