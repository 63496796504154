@import '../../styles/customMediaQueries.css';

.youPick {
  background-color: #0A195A;
  color: white;
  width: 100%;
  max-width: 100vw;
  padding: 48px 24px;

  height: auto;

  @media (--viewportMedium) {
    padding: 96px;
  }
}

.youPickTitle{
  composes: h2 from global;

  margin: auto;
  text-align: center;
  color: white;
  font-family: var(--fontFamilyBold);
  margin: 0 0 48px 0;
}

.youPickTitle span {
  color: var(--successColor);
}

.youPickCardContainer{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow: auto;
  color: white;
  gap: 24px;

  @media (--viewportMedium) {
    display: flex;
    gap: 24px;
  }

  @media (--viewportLarge) {
    flex-wrap: nowrap;
  }
}

.youPickCardBeach{
  width: 90%;
  min-width: 280px;
  height: 500px;
  background-image: url('../../assets/beach.png');
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 15px;

  &:hover {
    cursor: pointer;
  }

  @media (--viewportMedium) {
    min-width: none;
    background-position: center;
    min-width: 250px;
    height: 500px;
    width: 45%;
  }

  @media (--viewportLarge) {
    width: 24%;
  }

  @media (--viewportXLarge) {
    min-width: 280px;

  }
}

.youPickCardMountain{
  width: 100%;
  min-width: 280px;
  height: 500px;
  background-image: url('../../assets/mountain.png');
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 15px;
  &:hover {
    cursor: pointer;
  }

  @media (--viewportMedium) {
    min-width: none;
    background-position: center;
    min-width: 250px;
    height: 500px;
    width: 45%;
  }

  @media (--viewportLarge) {
    width: 24%;
  }

  @media (--viewportXLarge) {
    min-width: 280px;

  }
}

.youPickCardCity{
  width: 100%;
  min-width: 280px;
  height: 500px;
  background-image: url('../../assets/city.png');
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 15px;
  &:hover {
    cursor: pointer;
  }

  @media (--viewportMedium) {
    min-width: none;
    background-position: center;
    min-width: 250px;
    height: 500px;
    width: 45%;
  }

  @media (--viewportLarge) {
    width: 24%;
  }

  @media (--viewportXLarge) {
    min-width: 280px;

  }
}

.youPickCardCountrySide{
  width: 100%;
  min-width: 280px;
  height: 500px;
  background-image: url('../../assets/countryside.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 15px;
  &:hover {
    cursor: pointer;
  }


  @media (--viewportMedium) {
    min-width: none;
    background-position: center;
    min-width: 250px;
    height: 500px;
    width: 45%;

  }

  @media (--viewportLarge) {
    width: 24%;
  }

  @media (--viewportXLarge) {
    min-width: 280px;

  }
}

.youPickEmpty{
  height: 50%;
}
.youPickCardFooter{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  font-family: 'HumanSans-Bold', Arial, Helvetica, sans-serif;
  text-align: center;
  font-size: 48px;
  line-height: 54px;
  height: 50%;
  transition: .5s;
  padding: 0 0 32px;
  background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(0,0,0,0.8) 100%);
  border-radius: 0 0 15px 15px;

  @media (--viewportMedium) {
    font-size: 40px;
  }
}

.youPickCardFooter:hover {
  padding: 0 0 48px;
  transition-delay:0s;
  cursor: pointer;
}

.mobileCarousel {
  display: flex;
  flex-direction: column;
  padding: 32px;

  @media (--viewportMedium) {
    display: none;
  }
}
