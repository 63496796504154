@import '../../../../styles/customMediaQueries.css';

.root {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.media {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: auto;
    height: 275px;

    @media (--viewportLarge) {
        width: 100%;
    }
}

.image {
    border-radius: 8px 8px 0 0;
}

.text {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    text-align: center;
    color: var(--matterColorBright);
    background-color: var(--attentionColor);
    padding: 24px;

    border-radius: 0 0 8px 8px;

    &>p {
        margin: auto 0;
    }
}

.textBlog {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    text-align: center;
    color: var(--matterColorBright);
    padding: 24px;

    &>p {
        text-align: left;
        margin: auto 0;

        color: var(--inputColor);
        font-size: 14px;

        @media (--viewportMedium) {
            font-size: 16px;
        }
    }
}

.blog {
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
}

.blogTitle {
    composes: h3 from global;
    text-align: left;
}