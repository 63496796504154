@import '../../styles/customMediaQueries.css';

.root {
  padding: 48px 24px;

  @media (--viewportLarge) {
    padding: 96px;
  }
}

.owsTitle{
  composes: h2 from global;
  color: var(--attentionColor);
  margin: 0 0 24px 0;
}

.owsText{
  color: black;
  font-family: var(--fontFamilyRegular);
  line-height: 24px;
  font-size: 16px;
  margin: 0 0 24px 0;

  @media (--viewportMedium) {
    line-height: 28px;
    font-size: 24px;
    font-family: var(--fontFamilyRegular);
  }

}

.owsCards{
  text-align: center;
  margin: 0 0 60px 0;
  width: 100%;
  padding: 2% 0;
}

.owsCardsContainer{
  background-color: transparent;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;

  @media (--viewportLarge) {
    column-gap: 0;
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.owsCardOne{
  position: relative;
  background-color: var(--attentionColor);
  width: 100%;
  height: 360px;
  border-radius: 15px;

  @media (--viewportLarge) {
    width: 95%;
    height: 360px;
  }
}

.owsCardTwo{
  position: relative;
  background-color: var(--attentionColor);
  width: 100%;
  height: 360px;
  border-radius: 15px;

  @media (--viewportLarge) {
    width: 45%;
    height: 360px;
  }
}

/* TODO: Remove all containers and make css better */
.secondaryCards {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;
  width: 100%;

  @media (--viewportLarge) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.owsCardFooter{
  font-family: var(--fontFamilyMedium);
  width: 100%;
  height: 15%;
  display: -webkit-inline-box;
  -webkit-box-pack: justify;
  align-items: center;
  margin: auto;
  background: rgba(40,90,200, 0.84);
  border-radius: 0 0 15px 15px;
  color: #fff;
  padding: 12px;
  font-size: 18px;
  overflow: auto;
  position: absolute;
  bottom: 0;
  left: 0;

  @media (--viewportLarge) {
    font-size: 24px;
  }
}

.owsCardExtra{
  width: 100%;
  height: fit-content;
  padding: 4% 0 4% 0;
  display: none;
  font-family: var(--fontFamilyRegular);
}

.owsCardFooterText{
  font-size: 20px;
  text-align: left;
  width: 80%;
  height: 50%;
}

.owsCardFooterText .owsCardExtra{
  font-size: 16px;
  line-height: 20px;
  padding: 0 2% 0 2%;
}

.owsLearnMore{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: fit-content;
}

.owsCardFooterInfo {
  width: 100%;
  height: fit-content;
  font-family: 12px;
}

.owsCardImage{
  width: 100%;
  height: 100%;
}

.owsCardImages{
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px 15px;
}


.ourWorkStation {
  width: 100%;
  height: 100%;
}

.ourWorkStationTexts{
  width: 100%;
  margin: auto;
  text-align: center;

  @media (--viewportLarge) {
    width: 45%;
  }
}

.owsLearnMoreButton {
  border: 1px solid var(--attentionColor);
  border-radius: 10px;
  color: var(--attentionColor);
  font-size: 18px;
  padding: 8px 24px;
  font-family: var(--fontFamilyRegular);
}

.owsLearnMoreButton:hover {
  background-color: #285bc81b;
  border: 2px solid var(--attentionColor);
  text-decoration: none;
}
