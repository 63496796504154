@import '../../../../styles/customMediaQueries.css';

.root {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);

    @media (--viewportLarge) {
        flex-direction: row;
    }
}

.media {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: auto;

    @media (--viewportLarge) {
        width: 80%;
    }
}

.image {
    border-radius: 8px 8px 0 0;

    @media (--viewportLarge) {
        border-radius: 8px 0 0 8px;
    }
}

.text {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    text-align: left;
    color: var(--matterColorBright);
    padding: 24px;

    &>p {
        text-align: left;
        margin: auto 0;

        color: var(--matterColorDark);
        font-size: 16px;

        @media (--viewportMedium) {
            font-size: 20px;
        }
    }

    @media (--viewportLarge) {
        padding: 64px;
    }

}

.cta {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin-top: 24px;
    margin-bottom: 0;

    @media (--viewportLarge) {
        margin-top: 0;
        margin-bottom: 0;
        flex-direction: row;

    }
}


.blog {
}

.blogTitle {
    composes: h3 from global;
    text-align: left;
}