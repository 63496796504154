@import '../../styles/customMediaQueries.css';

/* Animation for the hero elements */

@keyframes animationTitle {
  0% {
    opacity: 0;
    top: -20px;
  }

  100% {
    opacity: 1;
    top: 0;
  }
}

/* Shared animation rules */
.animation {
  animation-name: animationTitle;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;
}

.root {
  composes: defaultBackgroundImage from global;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
  /* Text positioning is done with paddings */
}

.heroMainTitle {
  composes: marketplaceHeroTitleFontStyles from global;
  color: var(--matterColorLight);
  margin: 0 0 5% 0;

  position: relative;

  font-family: 'HumanSans-Bold', Helvetica, sans-serif;
  display: inline-block;

  @media (--viewportLarge) {
    max-width: var(--SectionHero_desktopTitleMaxWidth);
    font-size: 60px;
    line-height: 72px;
  }

  @media (--viewportLarge) and (min-height: 500px) and (max-height: 700px) {
    font-size: 48px;
    line-height: 64px;
  }
}

.heroMainTitle img {
  position: absolute;
  bottom: -24px;
  right: 64px;

  @media (--viewportMedium) {
    bottom: -24px;
    right: -20px;
  }

  @media (--viewportXLarge) {
    bottom: -24px;
    right: 96px;
  }
}


/* @media (--viewPortMedium){

} */

@media (min-width: 600px) and (max-width: 768px) {
  .heroMainTitle .titleImg {
    bottom: 0px;
    right: 0;
  }
}

@media (min-width: 300px) and (max-width: 600px) {
  .heroMainTitle .titleImg {
    bottom: -32px;
    right: -16px;
  }
}

.heroSubTitle {
  composes: h4 from global;
  font-family: var(--fontFamilyRegular);
  line-height: 28px;
  font-size: 16px;
  color: var(--matterColorLight);
  margin: 34px 0 0 0;

  @media (--viewportLarge) {
    font-size: 20px;
    line-height: 35px;
    margin: 24px 0 28px 0;
  }
}



/* Custom Css Classes for Hero section */

.customContainerHero {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  background: var(--marketplaceColor);

  @media (--viewportMedium) {
    height: 100%;
  }

  @media (--viewportLarge) {
    height: 100vh;
    flex-direction: row;
    position: relative;
  }

  @media (min-width: 1440px) {

  }
}

.customLeftTabHero {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #0A195A;
  height: fit-content;
  padding: 16px 0 36px;
  margin: 0;
  border-radius: 0 0 20px 20px;
  position: relative;
  top: 10px;
  left: 0;
  z-index: 2;

  @media (--viewportLarge) {
    width: 40%;
    height: 100%;
    border-radius: 0 20px 20px 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    margin: 0;
  }

  @media (--viewportLarge) and (min-height: 500px) and (max-height: 700px) {
    width: 50%;
  }
}

.customRightTabHero {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #0A195A;
  position:relative;

  @media (--viewportLarge) {
    width: 62%;
    height: auto;
  }
}

.customLogoHero {
  width: 70%;
  margin: auto;
}

.customTextHero {
  width: 80%;
  margin: auto auto;

  @media (--viewportLarge) {
    width: 60%;
    margin: var(--topbarHeightDesktop) auto auto 96px;
  }

  @media (--viewportLarge) and (min-height: 500px) and (max-height: 700px) {
    width: calc(100% - 160px);
    margin: var(--topbarHeightDesktop) 64px auto 96px;
  }

  @media (--viewportXLarge) {
    width: 60%;
    max-width: 480px;
    margin: 96px auto auto 96px;
  }
  
}

.banner {
  display: none;
  height: 130%;
  width: 100%;
  position: relative;
  bottom:0;
  left:0;

  @media (--viewportLarge) {
    display: block;
    object-position: center;
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
}

.bannerMobile {
  height: 130%;
  width: 100%;
  position: relative;
  bottom:0;
  left:0;

  @media (--viewportLarge) {
    display: none;
  }
}

.greenTitle {
  color: var(--successColor);
}

.searchContainer {
  position: absolute;
  left: 0;
  order: -3;

  background: transparent;
  border-radius: 0;

  padding: 0 12px 12px;
  width: 100%;
  z-index: 5;

  @media (--viewportMedium) {
    bottom: 0;
  }

  @media (--viewportLarge) {
    position: absolute;
    bottom:  36px;
    left: 20%;
    border-radius: 20px;
    padding: 0;
    width: 60%;
  }
}

.searchBox {
  background: var(--matterColorBright);
  border-radius: 20px;

  padding: 24px 32px 16px;
  width: 100%;

  @media (--viewportLarge) {
    padding: 24px 72px;
  }
}

.searchLink {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 72px;

  width: 100%;
}

.locationAutocompleteInput {
  flex-grow: 1;
  height: 36px;
  line-height: unset;
  padding-left: 0;
  margin: 0;
  border-bottom: 2px solid var(--inputColor);
  color: var(--inputColor);

  &:hover,
  &:focus {
    border-bottom-color: var(--matterColorDark);
    outline: none;
  }

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  @media (--viewportLarge) {
    height: 40px;
  }
}

.locationAutocompleteInputIcon {
  display: none;
}

.predictionsRoot {
  position: absolute;
  width: 100%;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  top: 36px;
  left: 0;
  background: var(--secondaryMarketplaceColor);
  color: var(--marketplaceColor) !important;
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  & ul {
    & li {
      color: var(--marketplaceColor);

      &:hover,
      &.highlighted {
        cursor: pointer;
        border-left: 6px solid var(--marketplaceColor);
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        color: var(--marketplaceColor);
      }
    }
  }

  @media (--viewportLarge) {
    top: 40px;
  }
}

.predictionsRoot .predictions {
  & li {
    color: var(--marketplaceColor) !important;

  }
}

.validLocation {
  border-bottom-color: var(--successColor);
}

.locationAddress {
  flex-shrink: 0;
}

.modal {
    /* Create context so the close button can be positioned within it */
    position: relative;

    /* Display and dimensions */
    display: flex;
    flex-direction: column;

    background-color: var(--matterColorLight);
    border-radius: 24px;
    border-bottom: none;

    width: 90%;
    min-height: 80vh;
    height: 80vh;
    overflow: auto;

    margin: auto;

  @media (--viewportLarge) {
      width: 80%;
      flex-grow: 0;
      min-height: auto;
      height: auto;

      margin-top: 12.5vh;
      margin-bottom: 12.5vh;
  }
}

.modalContent {
  display: flex;
  flex-direction: column;

  @media (--viewportLarge) {
    flex-direction: row;
  }
}

.newsletterLeft {
  width: 100%;
  height: 30vh;

  @media (--viewportLarge) {
    width: 50%;
    height: 100%;
  }
}

.newsletterImage {
  border-radius: 24px 24px 0 0;
  object-fit: cover;
  height: 100%;
  width: 100%;

  @media (--viewportLarge) {
    border-radius: 24px 0 0 24px;
  }
}

.newsletterRight {
  display: flex;
  flex-direction: column;
  padding: 24px 24px 24px 24px;

  @media (--viewportLarge) {
    padding: 64px;
  }
}

.newsletterTitle {
  color: var(--successColor);
  font-size: 32px;
  margin: 0;

  @media (--viewportLarge) {
    font-size: 45px;
    margin: 24px 0;
  }
}

.newsletterDescription {
  margin: 0;

  @media (--viewportLarge) {
    margin: 12px 0;
  }
}

.newsletterRightContent {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 12px;

  @media (--viewportLarge) {
    flex-direction: row;
    
    gap: 24px;
    margin-top: 24px;

  }
}

.newsletterInput {
  border-bottom-color: var(--inputColor);
}

.newsletterButton {
  background-color: var(--successColor);
  padding: 16px 32px;
  width: 100%;

  @media (--viewportLarge) {
    width: fit-content;
  }
}

.modalButton {
  border: 1px solid var(--marketplaceColor);
  padding: 0 24px;
  margin: 40px auto;
  min-width: 200px;
  width: fit-content;
}

.successContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
}