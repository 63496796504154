@import '../../styles/customMediaQueries.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  padding-top: 1px;
}

.title {
  margin-bottom: 24px;
}

.label {
  color: var(--successColor);
  font-family: var(--fontFamilyRegular);
  font-weight: bold !important;
  font-size: 16px;
  line-height: 28px;
  margin: 0 0 36px ;
}

.emph {
  text-decoration: underline;
}

.error {
  color: var(--failColor);
}

.inputHolder {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.inputWrapper {
  margin: 0 0 24px;
  width: 100%;

  & ::-webkit-calendar-picker-indicator {
    filter: invert(58%) sepia(89%) saturate(0%) hue-rotate(238deg) brightness(107%) contrast(87%);
  }

  @media (--viewportMedium) {
    width: calc(50% - 24px);
  }
}

.input {
  color: var(--matterColorBright);
  font-weight: 600;

  &::placeholder {
    font-weight: 500;
  }
}

.calendarWrapper {
  flex-grow: 1;
  position: relative;
  width: 100%;
  margin-bottom: 24px;
}

.buttonWrapper {
  display: flex;
  flex-direction: column;


  @media (--viewportLarge) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: fit-content;
    padding: 0 24px;
    margin-top: 100px;
    margin-left: auto;
  }
}

.listingButton {
  margin: 0 0 24px 0;
  flex-shrink: 0;
  background: var(--marketplaceColor);
  border: 1px solid var(--lightSecondary);
  color: var(--lightSecondary);

  &:hover,
  &:focus {
    color: var(--attentionColor);
    border: 2px solid var(--attentionColor);
    background-color: rgba(40, 90, 200, .2);
    text-decoration: none;
  }

  @media (--viewportLarge) {
    display: inline-block;
    width: fit-content;
    padding: 0 24px;
    margin: 100px 24px 24px;
  }
}

.backButton {
  margin-top: 24px;
  margin-bottom: 24px;
  flex-shrink: 0;
  background: var(--marketplaceColor);
  border: none;
  color: var(--lightSecondary);

  &:hover {
    color: var(--attentionColor);
    border: 2px solid var(--attentionColor);
    background-color: rgba(40, 90, 200, .2);
    text-decoration: none;
  }


  @media (--viewportLarge) {
    display: inline-block;
    width: fit-content;
    padding: 0 24px;
    margin-top: 100px;
  }
}

.icon {
  margin: 0 8px 0 0;
}

.tooltipDescription {
  display: flex;
  flex-direction: row;
  font-size: 16px;
  color: var(--matterColorDark);
}

.toolTipIcon {
  padding: 6px 0;
  height: auto;
  margin: auto 8px auto 0;
  min-width: 25%;
}

.tooltip {
  font-size: 16px;
  color: var(--lightSecondary);

  position: relative;
  z-index: 5;
}

.tooltipContainer {
  display: none;
  color: var(--marketplaceColor);
}

.tooltipContainerActive {
  display: flex;
  flex-direction: column;
  background: var(--matterColorBright);
  border-radius: 15px;
  align-items: center;
  gap: 8px;
  position: absolute;
  top: 0;
  left: 0;
  padding: 24px;
  width: calc(100vw - 72px);
  z-index: 5;

  @media (--viewportMedium) {
    width: 500px;
  }
}

.tooltipHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: var(--attentionColor);
  width: 100%;
}

@media (--viewportMedium) {
  .root {
    padding-top: 2px;
    margin-top: -16px;
  }
  .title {
    margin-bottom: 32px;
  }
}

@media (--viewportLarge) {
  .calendarWrapper {
    flex-grow: 0;
    margin-bottom: 24px;
  }
}
