.avatarBase {
  font-family: 'poppins', Helvetica, Arial, sans-serif;
  border-radius: 50%;

  /* Position possible initials to the center of the component */
  display: flex;
  align-items: center;
  justify-content: center;

  /* Colors */
  background-image: linear-gradient(
    -180deg,
    var(--marketplaceColorLight) 0%,
    var(--marketplaceColor) 100%
  );
  color: var(--matterColorLight);

  position: relative;

  &:hover {
    text-decoration: none;
  }
}

/* Small Avatar */

.root {
  composes: avatarBase;

  /* Layout */
  width: 40px;
  height: 40px;

  position: relative;
}

.socialLogin {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 16px;
  height: 16px;
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: var(--matterColorLight);
  display: flex;
  align-items: center;
  justify-content: center;

  & > img {
    width: 10px;
    height: 10px;
  }
}

.linkedIn,
.linkedin {
  background-color: var(--linkedInColor);
}

.linkedInCard {
  position: absolute;
  bottom: 16px;
  left: 20%;
  border: none;
  border-radius: 4px;
  background-color: var(--linkedInColor);
  font-size: 16px;
  text-wrap: nowrap;
  display: flex;
  align-items: center;
  justify-content: space-between;

  min-width: 60%;
  max-width: 60%;

  & > img {
    width: 24px;
    height: 24px;
    margin: auto 8px;
  }

  & > p {
    background: var(--matterColorLight);
    color: var(--linkedInColor);
    font-size: 16px;
    margin: 0;
    padding: 0 8px;
    border-radius: 0 4px 4px 0;
  }
}

.initials {
  font-family: var(--fontFamilyMedium);
  font-size: 14px;
  font-weight: var(--fontWeightBold);
}

.avatarImage {
  width: 100%;
  height: 100%;
  border-radius: inherit;
}

/* Medium Avatar */

.mediumAvatar {
  composes: avatarBase;

  /* Fixed dimensions */
  width: 60px;
  height: 60px;
}

.mediumAvatar .initials {
  font-size: 20px;
  font-weight: var(--fontWeightSemiBold);
}

/* Large Avatar */

.largeAvatar {
  composes: avatarBase;

  /* Fixed dimensions */
  width: 96px;
  height: 96px;
}

.largeAvatar .initials {
  font-size: 30px;
  font-weight: var(--fontWeightSemiBold);
}

.bannedUserIcon {
  width: 100%;
  height: 100%;
}
