.link {
  display: inline-block;
  color: var(--successColor);
}

.greenText {
  color: var(--successColor);
}

.blueText {
  color: var(--attentionColor);
}

.whiteText {
  color: var(--matterColorBright);
}

.blackText {
  color: var(--matterColorDark);
}

.martketColorText {
  color: var(--marketplaceColor);
}