@import '../../styles/customMediaQueries.css';

:global(.image-gallery-content),
:global(.image-gallery-slide-wrapper),
:global(.image-gallery-swipe),
:global(.image-gallery-slides),
:global(.image-gallery-slide) {
  width: 100%;
  height: 100%;
}

:global(.image-gallery-slide-wrapper) {
  background-color: var(--matterColorDark);

  @media (--viewportMedium) {
    padding: 0 10%;
  }
}

.root {
  position: relative;
  width: 100%;
  height: 100vh;
  padding: 0;
  background-color: var(--matterColorDark);

  @media (--viewportMedium) {
    padding: 100px 0;
  }
}

.noImage {
  border: 1px solid var(--matterColorNegative);
  border-radius: 4px;
}

.itemCentering {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.item {
  max-width: 100%;
  max-height: 100%;
}

.arrowHead {
  fill: var(--matterColorLight);
  stroke: var(--matterColorLight);
}

.navLeft,
.navRight {
  position: absolute;
  width: 10vw;
  min-width: 52px;
  height: 100%;
  border: 0;
  padding: 0;
  z-index: 1;

  /* center content */
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;

    & .navArrowWrapper {
      background-color: rgba(255, 255, 255, 1);
    }
    & .arrowHead {
      fill: var(--matterColorAnti);
      stroke: var(--matterColorAnti);
    }
  }
}

.navLeft {
  left: 0;
  justify-content: flex-start;
  padding-left: 12px;

  @media (--viewportMedium) {
    padding-left: 3vw;
  }

  & .navArrowWrapper {
    padding-right: 2px;
  }
}

.navRight {
  right: 0;
  justify-content: flex-end;
  padding-right: 12px;

  @media (--viewportMedium) {
    padding-right: 3vw;
  }

  & .navArrowWrapper {
    padding-left: 2px;
  }
}

.navArrowWrapper {
  display: none;

  width: 40px;
  height: 40px;
  border-radius: 50%;

  background-color: transparent;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (--viewportMedium) {
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.imageWrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.imageIndex {
  /* Fonts */
  composes: h4 from global;
  font-weight: var(--fontWeightSemiBold);

  /* Position and dimensions */
  position: absolute;
  top: 21px;
  left: 20px;
  margin: 0;

  /* Colors */
  color: var(--matterColorLight);

  @media (--viewportMedium) {
    margin: 0;
    font-weight: var(--fontWeightSemiBold);
  }
}


.thumbnailGrid {
  background-color: var(--matterColorDark);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;

  padding: 24px 0;
}

.thumbnailWrapper {
  width: 25%;
  position: relative;
  cursor: pointer;
}

.aspectWrapper {
  padding-bottom: calc(100% * (2 / 3));
}

.thumbnail {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--borderRadius);
}

.gallery {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: var(--matterColorDark);
}

.closeButton {
  display: flex;
  position: absolute;
  top: -42px;
  left: 0;
  padding: 16px;
  z-index: 1;

  color: var(--matterColorAnti);
  font-size: 16px;
  font-weight: var(--fontWeightBold);

  cursor: pointer;
}

.selectedCarousel {
  position: relative;
  width: 100%;
  padding: 0;
  background-color: var(--matterColorDark);
}

.hide {
  display: none;
}

.icon {
  margin: auto 0;
  fill: var(--matterColorAnti);
  stroke: var(--matterColorAnti);
}

.text {
  margin: auto 0 auto 8px;
}