@import '../../styles/customMediaQueries.css';

.root {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
}

.label {
  font-size: 16px;
  font-weight: var(--fontWeightMedium);
  margin: 0 0 8px ;
}

.labelGreen {
  color: var(--successColor);
  font-size: 16px;
  font-weight: var(--fontWeightSemiBold);
  margin: 8px 0 0;
}

.firstFields {
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (--viewportLarge) {
    flex-direction: row;
  }
}

.imagesField {
  margin: 0;
  background-color: var(--marketplaceColor);

}

.thumbnail {
  margin: 24px 0 0 0;

  @media (--viewportLarge) {
    margin: 0 24px 24px 0;

    &:nth-child(even) {
      margin: 0 0 24px 0;
    }
  }
}

.secondThumbnail {
  margin: 24px 0 0 0;

  @media (--viewportLarge) {
    width: calc(33.33% - 24px);
    margin: 0 24px 24px 0;
  }
}

.addImageWrapper {
  float: left;
  position: relative;
  width: 100%;
  margin: 24px 0 0 0;
  overflow: hidden;
  background-color: var(--marketplaceColor);

  &::after {
    content: '.';
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }

  @media (--viewportLarge) {
    margin: 0 0 24px 0;
  }
}

.secondAddImageWrapper {
  float: left;
  position: relative;
  width: 100%;
  margin: 24px 0 0 0;
  overflow: hidden;
  background-color: var(--marketplaceColor);

  &::after {
    content: '.';
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }

  @media (--viewportLarge) {
    width: calc(33.33% - 24px);
    margin: 0 0 24px 0;
  }
}

.addImageWrapperDisabled {
  display: none;
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectRatioWrapper {
  padding-bottom: calc(100% * (2 / 3));
}

.addImage {
  /* Layout */
  /* Maintain aspect ratio */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;

  /* Colors */
  background-color: var(--marketplaceColor);

  @media (--viewportLarge) {
    background-color: var(--marketplaceColor);
  }

  border-style: dashed;
  border-color: var(--matterColorNegative);
  border-width: 2px;
  border-radius: 15px;

  /* Behaviour */
  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    border-color: var(--matterColorAnti);
  }
}

.chooseImageText {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chooseImage {
  composes: h4 from global;
  background-color: var(--matterColorBright);
  border-radius: 10px;
  color: var(--matterColor);
  font-weight: var(--fontWeightSemiBold);

  margin-top: 0;
  margin-bottom: 0;
  padding: 12px 24px;

  @media (--viewportLarge) {
    font-weight: var(--fontWeightSemiBold);
    margin-top: 0;
    margin-bottom: 0;
  }
}

.chooseImageTwo {
  composes: h4 from global;
  background-color: var(--attentionColor);
  border-radius: 50px;
  color: var(--matterColorBright);
  fill: var(--matterColorBright);
  font-weight: var(--fontWeightSemiBold);

  margin-top: 0;
  margin-bottom: 0;
  padding: 24px;

  @media (--viewportLarge) {
    font-weight: var(--fontWeightSemiBold);
    margin-top: 0;
    margin-bottom: 0;
  }
}

.addIcon {
  display: flex;
  width: 12px;
  height: 12px;
  transform: scale(1.5);
  color: var(--matterColorBright);
  fill: var(--matterColorBright);
}

.imageTypes {
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.addImageInput {
  display: none;
}

.imageRequiredWrapper {
  width: 100%;
  clear: both;
}

.fieldWrapper {
  display: flex;
  flex-direction: column;

  width: 100%;

  @media (--viewportMedium) {
    width: 50%;
  }
}

.tip {
  font-size: 16px;
  composes: h5 from global;
  line-height: 24px;

  flex-shrink: 0;
  color: var(--successColor);
  margin-top: 12px;
  margin-bottom: 60px;
  width: 100%;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.error {
  color: var(--failColor);
}

.buttonWrapper {
  display: flex;
  flex-direction: column;


  @media (--viewportLarge) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: fit-content;
    padding: 0 24px;
    margin-top: 100px;
    margin-left: auto;
  }
}


.listingButton {
  margin: 0 0 24px 0;
  flex-shrink: 0;
  background: var(--marketplaceColor);
  border: 1px solid var(--lightSecondary);
  color: var(--lightSecondary);

  &:hover,
  &:focus {
    color: var(--attentionColor);
    border: 2px solid var(--attentionColor);
    background-color: rgba(40, 90, 200, .2);
    text-decoration: none;
  }

  @media (--viewportLarge) {
    display: inline-block;
    width: fit-content;
    padding: 0 24px;
    margin: 100px 24px 24px;
  }
}

.backButton {
  margin-top: 24px;
  margin-bottom: 24px;
  flex-shrink: 0;
  background: var(--marketplaceColor);
  border: none;
  color: var(--lightSecondary);

  &:hover {
    color: var(--attentionColor);
    border: 2px solid var(--attentionColor);
    background-color: rgba(40, 90, 200, .2);
    text-decoration: none;
  }


  @media (--viewportLarge) {
    display: inline-block;
    width: fit-content;
    padding: 0 24px;
    margin-top: 100px;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loaderWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;

  background-color: rgba(0, 0, 0, .8);

  z-index: 1000;
}

.loader {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 5px solid var(--lightSecondary);
  border-top: 5px solid var(--attentionColor);
  animation: spin 1s linear infinite;
}