@import '../../styles/customMediaQueries.css';


.root {
  width: 100%;
  display: flex;
  box-sizing: border-box;
}

.carousel {
  width: 100%;
  max-width: 1600px; /* ajusta el valor máximo según tu diseño */
  margin: 0 auto; /* centra el contenedor horizontalmente */
}

.sectionTitle{
  composes: h2 from global;
  
  text-align: center;
  color: var(--marketplaceColorLight);
  margin: 48px 0 16px 0;
}

.carousel {
  width: 100%;
  overflow: hidden;
}

.carousel .some {
  display: flex;
  transition: transform 0.5s;
}

.carousel .some.paused {
  transition: none;
}

.carousel .some > * {
  flex: 0 0 auto;
  width: 100%;
  margin-right: 20px; /* Espacio entre los bloques */
}

.carousel .some > *:last-child {
  margin-right: 0;
}

