@import '../../styles/customMediaQueries.css';

/* Custom css classes */
.root{
  width: 100%;
  display: flex;
  padding: 48px 24px 48px 24px;
  flex-direction: column;
  align-items: center;
  background-color: var(--secondaryMarketplaceColor);

  @media (--viewportLarge) {
    padding: 48px 24px;
    flex-direction: row;
    padding: 96px;
  }
}

.imageContainer{
  width: 100%;

  @media (--viewportLarge) {
    width: 50%;
  }
}

.content{
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 32px;

  @media (--viewportLarge) {
    margin-top: 0;
    width: 50%;
    padding: 0 0 0 128px;
  }
}

.image{
  margin: auto;
  width: 100%;
  border-radius: 20px;
  box-shadow: -1px 8px 20px rgba(0, 0, 0, 0.3);

  @media (--viewportLarge) {
    box-shadow: -10px 40px 20px rgba(0, 0, 0, 0.3);
  }
}

.whyHomeBaseTitle{
  composes: h2 from global;
  color:#0A195A;
}

.whyHomeBaseTitle strong {
  color: var(--successColor);
}

.whyHomeBaseDescriptionContainer{
  width: 60%;
  margin: 5% auto 0 auto;
}

.whyHomeBaseDescription{
  font-family: var(--fontFamilyRegular);
  color:#0A195A;
  font-size: 16px;
  width: 90%;
  line-height: 28px;
  @media (--viewportMedium) {
    font-size: 24px;
  }
}

.whyHomeBaseSubtitle {
  position: relative;
  width: 90%;
  color: black;
  font-family: var(--fontFamilyRegular);;
  font-weight: 700;
  font-size: 24px;
  padding: 0 0 0 20%;
}

.customButtonElement {
  background: var(--marketplaceColor);
  font-family: var(--fontFamilyRegular);
  border-radius: 10px;
  border: none;
  color: white;
  font-size: 18px;
  padding: 8px 24px;
  width: fit-content;
  margin: 21px 0 0;
}

.customButtonElement:hover {
  text-decoration: none;
  filter: brightness(1.2);
}
