@import '../../../../styles/customMediaQueries.css';

.root {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    @media (--viewportLarge) {
        flex-direction: row;
    }
}

.media {
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (--viewportLarge) {
        width: 25%;
    }
}

.text {
    text-align: left;

    border-bottom: 1px solid var(--inputColor);
    padding-bottom: 24px;
    cursor: pointer;
}

.titleContainer {
    display: flex;
    flex-direction: row;
    margin: 0 0;

    @media (--viewportLarge) {
        margin: 0;
    }
}

.index {
    composes: h3 from global;
    display: none;
    color: var(--successColor);
    margin-right: 16px;

    @media (--viewportLarge) {
        display: block;
    }
}

.title {
    color: var(--marketplaceColor);
    line-height: 30px;
}

.addIcon {
    margin: auto 0 auto auto;

    width: 28px;
    height: 28px;
}

.text p {
    color: var(--marketplaceColor);
    font-size: 16px;
    line-height: 26px;

    margin: 0 0 0 42px;
}