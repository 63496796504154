.markdownImage {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
}

.fieldImage {
  width: 100%;
  height: 100%;
  border-radius: 8px 0 0 8px;
  object-fit: cover;
}
